import React, { useState, useEffect } from 'react';
import { Dialog, IconButton, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import CentralCalls from '../../centralCalls';
import TreeViewComponent from '../components/TreeViewComponent';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    select: {
        minWidth: 200,
    },
    accordion: {
        borderRadius: '15px',
        margin: '10px 0',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}));

const CompletePo = ({ lot_oid }) => {
    const classes = useStyles();
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [activeFilter, setActiveFilter] = useState({ name_id: 'open', name: 'Open' });
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDateChange = (item) => {
        if(item.selection.startDate && item.selection.endDate){
            const newStartDate = item.selection.startDate;
            const newEndDate = item.selection.endDate;
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
        
    };

    const handleTreeViewItemClick = (node) => {
        setSelectedNode(node);
        setDeleteConfirmOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (selectedNode) {
            const deleteCheck = await CentralCalls.callStoredProcedureWithParams('sp_undoPoCheck', { check_oid: selectedNode.oid });
            setDeleteConfirmOpen(false);
        }
    };

    const handleCloseDelete = () => {
        setDeleteConfirmOpen(false);
    };
    const formatDate = (date) => {
        if (!date) return '';
        return format(date, 'MM/dd/yyyy');
    };
    useEffect(() => {
        const getLotTitle = async () => {
            const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', { lot_oid: lot_oid });
        };
        getLotTitle();
    }, [lot_oid]);

    return (
        <>

            {!activeFilter.hideCalendar ? (
                <>
                <br/>
                    <IconButton aria-label="save" onClick={() => setDialogOpen(true)} size="medium">
                        <FontAwesomeIcon icon={faCalendar} />
                    </IconButton>
                    {formatDate(startDate)} - {formatDate(endDate)}
                    <br />
                </>
            ) : null}
            {/* <DateRangePicker
                onChange={handleDateChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                direction="horizontal"
                locale={enUS}
                ranges={[{
                    startDate: startDate,
                    endDate: endDate,
                    key: 'selection',
                }]}
                inputRanges={[]}
            /> */}
            <TreeViewComponent sp_name={'GetCheckDataPreview'} params={{ lot_oid: lot_oid, start_date:CentralCalls.sqlFormatdate(startDate) , end_date: CentralCalls.sqlFormatdate(endDate)}} rowClickFunction={handleTreeViewItemClick}  sortOrder={{
                    'date_paid': 'desc',
                    'name': 'desc'

                }} />

            <Dialog open={deleteConfirmOpen} onClose={handleCloseDelete}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete {selectedNode?.name}?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialogOpen} onClose={() => { setDialogOpen(false); }}>
    <DialogContent style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '400px',
        marginLeft: '-250px' // Adjust height based on your needs
    }} >
        <DateRangePicker
            onChange={handleDateChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            direction="horizontal"
            locale={enUS}
            ranges={[{
                startDate: startDate,
                endDate: endDate,
                key: 'selection',
            }]}
            inputRanges={[]}
        />
    </DialogContent>
    <DialogActions>
        <Button onClick={() => { setDialogOpen(false); }}>Ok</Button>
    </DialogActions>
</Dialog>

        </>
    );
};

export default CompletePo;
