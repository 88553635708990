import { React, useState, useEffect } from 'react';// adjust this import path to where your JSONTableEditor file is located
import { Dialog, Select, MenuItem, DialogActions,  DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import DynamicForm from '../../DynamicForm';
import JSONTableEditor from '../../JSONTableEditor';
import CentralCalls from '../../../centralCalls';
import { useTheme } from '@mui/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import DeleteConfirmationPopup from '../../DeleteConfirmationPopup';

const useStyles = makeStyles(theme => ({
  customHeader: {
    borderRadius: '10px',
    fontSize: 'large',
    padding: '0px',
    margin: '0px',
    border: 'black solid'
  }
}))
const DeletedPhase = ({ phaseType, handleWipLinkClick, startDate, endDate,refreshMenu }) => {
  const [refreshKey, setRefreshKey] = useState(false);
  const procedureDetailsDeleted = {
    procedureName: 'ui_sp_getPhasePageData',
    parameters: { phase_type: phaseType, page_type: 'deleted', start_date: CentralCalls.sqlFormatdate(startDate), end_date: CentralCalls.sqlFormatdate(endDate) },  // If there are any parameters, define them here as an object
  };
  const handleDeleteSave = async (phaseRow) => { setRefreshKey(!refreshKey);refreshMenu(); }
  const handleRemoveDeleted = async (phaseRow) => {
    await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'open' }, 'phase_status', 'update');
    setRefreshKey(!refreshKey);
    refreshMenu();
    //setRefresh(new Date().getTime());
  }
  const customFunctionsDeleted = [
    {
      display_name: 'Revert',
      type: 'undo', // type must correspond to a FontAwesome icon name
      func: handleRemoveDeleted
    },
    {
      display_name: 'WIP',
      type: 'overview',
      func: handleWipLinkClick
    }
  ]
  return (
    <>
      <JSONTableEditor
        key={'deleted'}
        procedureDetails={procedureDetailsDeleted}
        onSave={handleDeleteSave}
        sortOrder={{
          'date_scheduled': 'desc'
        }}
        hideEdit={true}
        customFunctions={customFunctionsDeleted} />
    </>
  );
}
export default DeletedPhase;
