import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    subRow: {
        backgroundColor: '#f9f9f9',
    },
    indentCell: {
        paddingLeft: theme.spacing(4),
    },
}));

const SubRow = ({ item }) => {
    const classes = useStyles();
    return (
        <TableRow className={classes.subRow}>
            <TableCell className={classes.indentCell} colSpan={1}></TableCell>
            <TableCell>{item.item_desc}</TableCell>
            <TableCell>{item.cost}</TableCell>
        </TableRow>
    );
};

const SubRows = ({ rows }) => {
    const classes = useStyles();
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.indentCell} colSpan={1}></TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Cost</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <SubRow key={index} item={row} />
                ))}
            </TableBody>
        </Table>
    );
};

export default SubRows;
