import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select, } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LotFilter from '../workInProgress/LotFilter';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CentralCalls from '../../centralCalls';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    select: {
        minWidth: 200,
    },
    accordion: {
        borderRadius: '15px',
        margin: '10px 0',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}));
const Pos = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [StatusDialogOpen, setStatusDialogOpen] = useState(false);
    const [AddPODialogOpen, setAddPODialogOpen] = useState(false);
    const [StatusDialogOid, setStatusDialogOid] = useState(null);
    const [SelectedPoOid, setSelectedPoOid] = useState(0);
    const [SelectedLotOid, setSelectedLotOid] = useState(null);
    const [SelectedInsertLotOid, setSelectedInsertLotOid] = useState(null);
    const [SelectedPhaseOid, setSelectedPhaseOid] = useState(null);
    const [completeLotCloseKey, setCompleteLotCloseKey] = useState(50);
    const [lotTitle, setLotTitle] = useState('no title');
    //const [Phases, setPhases] = useState(null);
    useEffect(() => {
        const getLotTitle = async () => {
          const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', {lot_oid : SelectedLotOid });
          setLotTitle(title.title);
        }
        getLotTitle();
      }, [SelectedLotOid]);
    const handleCompletePo = async (poRow) => {
        setStatusDialogOpen(false);
    };
    const handleSavePermit = async (poRow) => {
        setSelectedPoOid(poRow.oid);
        setStatusDialogOid(poRow.oid);
    };
    const handlePostUnDelete = async (poRow) => {
       
    };
    const addPOClick = async () => {
        await CentralCalls.upsertData({ 'phase_oid': SelectedPhaseOid }, 'po', 'insert');
        setSelectedLotOid(null);
        setSelectedPhaseOid(null);
        setAddPODialogOpen(false);
    }
    const openCustomPO = async () => {
        setAddPODialogOpen(true);
    }
    const handleLotSelected = async (lot_oid) => {
        //const rows = await CentralCalls.fetchTableData('vw_phase_stat', { 'lot_oid': lot_oid, 'status': 'complete' });
        //setPhases(rows);
        setSelectedInsertLotOid(lot_oid);
    }
    const handlePhaseChange = async (event) => {
        setSelectedPhaseOid(event.target.value);
    }
    const handlePostInsertPo = async (poRow) => {
        //setSelectedPhaseOid(event.target.value);
        setAddPODialogOpen(false);
    }
    const handleOpenCompletePo = async (poRow) => {
        setStatusDialogOpen(true);
        setSelectedPoOid(poRow.oid);
        setStatusDialogOid(poRow.oid);
        setSelectedLotOid(poRow.lot_oid);
    }
    const checkDisplayComplete = (poRow) => {
        if (poRow.date_billed && poRow.date_billed !== null && poRow.po_number && poRow.po_number != null && poRow.price && poRow.price > 0) { return true; } else { return false; }
    }
    const undeletedRow = (deletedRow) => {
        CentralCalls.upsertData({deleted : 0, oid : deletedRow.oid}, 'po', 'update');
    }
    const handleWipLinkClick = async (phaseRow) => {
        const path = '/wip?lot_oid=' + phaseRow.lot_oid;
        navigate(path);
      }
    const handleRevertCompletePO = async(phaseRow) => {
        console.log(phaseRow);
        await CentralCalls.upsertData({oid : phaseRow.oid, date_paid : null}, 'po','update');
        setCompleteLotCloseKey(completeLotCloseKey + 1);
        console.log('row updated');
    }
    // use React.useState and other hooks here if necessary
    const customFunctions = [
        {
            display_name: 'Complete',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: handleOpenCompletePo,
            displayCheck: checkDisplayComplete
        },
        {
          display_name: 'WIP',
          type: 'overview',
          func: handleWipLinkClick
        }
    ]
    const customFunctionsComplete = [
        {
            display_name: 'Undo',
            type: 'revert', // type must correspond to a FontAwesome icon name
            func: handleRevertCompletePO
        },
        {
          display_name: 'WIP',
          type: 'overview',
          func: handleWipLinkClick
        }
    ]
    const customFunctionsDelete = [
        {
            display_name: 'Complete',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: handleOpenCompletePo,
            displayCheck: checkDisplayComplete
        },
        {
            display_name: 'Revert',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: undeletedRow
        }
    ]
    
    const procedureDetails = {
        procedureName: 'ui_sp_getPoPageData',
        parameters: { 'complete': 0 },  // If there are any parameters, define them here as an object
    };
    const procedureRecentDetails = {
        procedureName: 'ui_sp_getPoPageData',
        parameters: { 'complete': 1 },  // If there are any parameters, define them here as an object
    };
    const procedureDeletedDetails = {
        procedureName: 'ui_sp_getPoPageData',
        parameters: { 'deleted': 1 },  // If there are any parameters, define them here as an object
    };
    const procedureDetailsCompletion = {
        procedureName: 'ui_sp_getPoCompletionPageData',
        parameters: { 'po_oid': SelectedPoOid },  // If there are any parameters, define them here as an object
    };
    const procedureDetailsInsertPo = {
        procedureName: 'ui_sp_insertPoForm',
        parameters: { 'lot_oid': SelectedInsertLotOid }
    };
    const customFunctionsCompletion = [
        {

        }
    ]
    return (
        <div>
            <h1>PO's
                <Tooltip title="Add PO">
                    <IconButton color="primary" aria-label="save" onClick={openCustomPO} size="medium">
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            </h1>
            <JSONTableEditor
                procedureDetails={procedureDetails}
                onSave={handleSavePermit}
                allowDelete={true}
                sortOrder={{
                    'builder': 'desc',
                    'neighborhood': 'desc',
                    'lot': 'desc'
                }} customFunctions={customFunctions} />
            <br />
            <h1>Recently Complete</h1>
            <JSONTableEditor
                procedureDetails={procedureRecentDetails}
                onSave={handleSavePermit}
                key={completeLotCloseKey}
                sortOrder={{
                    'builder': 'desc',
                    'neighborhood': 'desc',
                    'lot_num': 'asc'
                }}
                customFunctions={customFunctionsComplete} />
             <h1>Recently Deleted</h1>
            <JSONTableEditor
                procedureDetails={procedureDeletedDetails}
                onSave={handlePostUnDelete}
                hideEdit={true}
                sortOrder={{
                    'builder': 'desc',
                    'neighborhood': 'desc',
                    'lot_num': 'asc'
                }}
                customFunctions={customFunctionsDelete} />

            <Dialog fullWidth={true} maxWidth='lg' open={StatusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogTitle><h1>Complete PO</h1><h4>{lotTitle}</h4></DialogTitle>
                <DialogContent >
                    <DynamicForm
                        procedureDetails={procedureDetailsCompletion}
                        onSave={handleCompletePo}
                        saveName='Save'
                        crudType='update' />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth={true} maxWidth='lg' open={AddPODialogOpen} onClose={() => setAddPODialogOpen(false)}>
                <DialogTitle><h1>New PO</h1></DialogTitle>
                <DialogContent >
                    <LotFilter onLotChange={handleLotSelected} /><br />
                    <DynamicForm
                        procedureDetails={procedureDetailsInsertPo}
                        onSave={handlePostInsertPo}
                        saveName='Add PO'
                        crudType='insert' />                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddPODialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Pos;
