import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select, Switch, FormControlLabel } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LotFilter from '../workInProgress/LotFilter';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import PoItems from './PoItems';
import CentralCalls from '../../centralCalls';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    select: {
        minWidth: 200,
    },
    accordion: {
        borderRadius: '15px',
        margin: '10px 0',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}));
const ActivePo = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [StatusDialogOpen, setStatusDialogOpen] = useState(false);
    const [StatusDialogOid, setStatusDialogOid] = useState(null);
    const [SelectedPoOid, setSelectedPoOid] = useState(0);
    const [SelectedLotOid, setSelectedLotOid] = useState(null);
    const [selectedPo, setSelectedPo] = useState({});
    const [openPoItems,setOpenPoItems] = useState(false);
    const [completeOnlyChecked, setCompleteOnlyChecked] = useState(true);
    const [completeLotCloseKey, setCompleteLotCloseKey] = useState(50);
    const [lotTitle, setLotTitle] = useState('no title');
    useEffect(() => {
        const getLotTitle = async () => {
          const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', {lot_oid : SelectedLotOid });
          setLotTitle(title.title);
        }
        getLotTitle();
      }, [SelectedLotOid]);
    const handleOpenPoItems = async (poRow) => {
        setOpenPoItems(true);
        setSelectedPo(poRow);
    }
    const handleCompletePo = async (poRow) => {
        setStatusDialogOpen(false);
    };
    const handleSavePermit = async (poRow) => {
        setSelectedPoOid(poRow.oid);
        setStatusDialogOid(poRow.oid);
    };
    const handlePostUnDelete = async (poRow) => {
       
    };
    const handleOpenCompletePo = async (poRow) => {
        setStatusDialogOpen(true);
        setSelectedPoOid(poRow.oid);
        setStatusDialogOid(poRow.oid);
        setSelectedLotOid(poRow.lot_oid);
    }
    const handleCompleteOnlyChange = (event) => {
        setCompleteOnlyChecked(event.target.checked);
      };
    const checkDisplayComplete = (poRow) => {
        if (poRow.date_billed && poRow.date_billed !== null && poRow.po_number && poRow.po_number != null && poRow.price && poRow.price > 0) { return true; } else { return false; }
    }
    const undeletedRow = (deletedRow) => {
        CentralCalls.upsertData({deleted : 0, oid : deletedRow.oid}, 'po', 'update');
    }
    const handleWipLinkClick = async (phaseRow) => {
        const path = '/wip?lot_oid=' + phaseRow.lot_oid;
        navigate(path);
      }
    const handleRevertCompletePO = async(phaseRow) => {
        console.log(phaseRow);
        await CentralCalls.upsertData({oid : phaseRow.oid, date_paid : null}, 'po','update');
        setCompleteLotCloseKey(completeLotCloseKey + 1);
        console.log('row updated');
    }
    
    // use React.useState and other hooks here if necessary
    const customFunctions = [
        {
            display_name: 'Complete',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: handleOpenCompletePo,
            displayCheck: checkDisplayComplete
        },
        {
          display_name: 'WIP',
          type: 'overview',
          func: handleWipLinkClick
        },
        {
            display_name: 'Line Items',
            type: 'list', 
            func: handleOpenPoItems
        }
    ]

    const procedureDetails = {
        procedureName: 'ui_sp_getPoPageData2',
        parameters: { 'complete': completeOnlyChecked ? 1 : 0},
    };
    const procedureDetailsCompletion = {
        procedureName: 'ui_sp_getPoCompletionPageData',
        parameters: { 'po_oid': SelectedPoOid },
    };
    
    return (
        <div>
                <FormControlLabel
      control={
        <Switch
          checked={completeOnlyChecked}
          onChange={handleCompleteOnlyChange}
          name="nonCompleteToggle"
          color="primary"
        />
      }
      label="Complete Only"
    />
            <JSONTableEditor
                procedureDetails={procedureDetails}
                onSave={handleSavePermit}
                allowDelete={false}
                limitHeight={false}
                sortOrder={{
                    'builder': 'desc',
                    'neighborhood': 'desc',
                    'lot': 'desc'
                }} customFunctions={customFunctions} />
            <br />
            <Dialog fullWidth={true} maxWidth='lg' open={StatusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogTitle><h1>Complete PO</h1><h4>{lotTitle}</h4></DialogTitle>
                <DialogContent >
                    <DynamicForm
                        procedureDetails={procedureDetailsCompletion}
                        onSave={handleCompletePo}
                        saveName='Save'
                        crudType='update' />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth='lg' open={openPoItems} onClose={() => setOpenPoItems(false)}>
                <DialogTitle><h1>PO Line Items</h1></DialogTitle>
                <DialogContent >
                    <PoItems poOid={selectedPo.oid} ></PoItems>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPoItems(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ActivePo;