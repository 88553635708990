import React, { useState, useEffect } from 'react';
import {
    Checkbox, Table, TableBody, TableCell, TableHead, TableRow, IconButton, TextField, Typography, Select,
    MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiPath } from '../var';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    tableHeader: {
        backgroundColor: '#0B426B',
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    scrollableDiv: {
        overflowX: 'auto',
        overflowY: 'auto',
        maxHeight: '300px', // Or however tall you want your table to be.
    },
    stickyHeader: {
        position: 'sticky',
        top: '0',
        zIndex: 1,
        backgroundColor: '#0B426B', // Same color as tableHeader
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
}));
const GenericEdit = ({ tableName, onInsert, whereClause }) => {
    const classes = useStyles();
    const [editingRow, setEditingRow] = useState(null);
    const [formData, setFormData] = useState({});
    const [columns, setColumns] = useState([]);
    // const [idToDelete, setIdToDelete] = useState(null);
    useEffect(() => {
        fetchColumns();
        fetchData();
    }, [onInsert]);
    const [sortConfig, setSortConfig] = useState(null);
    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };
    const fetchData = () => {
        fetch(apiPath + '/api/table_select', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tableName, whereClause : whereClause }),
        })
            .then(response => response.json())
            .then(fetchedData => {  
                //console.log('rows:', fetchedData.rows);
            setData(fetchedData) })
            .catch(error => console.error('Error fetching data:', error));
    };
    const fetchColumns = async () => {
        fetch('/api/call-stored-procedureWithParam2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ procedureName: 'sp_getPageMetadata', param: tableName }),
        })
            .then(response => response.json())
            .then(data => setColumns(data.columns))
            .catch(error => console.error('Error:', error));
    };
    const [data, setData] = useState([]);
    const handleEdit = (row) => {
        setEditingRow(row.oid);
        setFormData(row);
    };
    const handleSave = async () => {
        
        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            if (column.required && !formData[column.name]) {
                alert(`Please fill the ${column.name} field.`);
                return;
            }
        }
        fetch(apiPath + '/api/upsert', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tableName: tableName, changeType: 'update', ID: editingRow, jsonObj: formData }),
        })
            .then(response => response.json())
            .then(result => {
                setFormData({});
                setEditingRow(null);
                fetchData(); // Refresh the data after saving
            })
            .catch(error => console.error(`Error updating ${tableName}:`, error));
    };
    // Handle the rest methods like handleDelete, handleClickOpenDeleteDialog, handleCloseDeleteDialog as in your original code
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const handleDelete = async () => {
        fetch(apiPath + '/api/upsert', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tableName, changeType: 'delete', ID: itemToDelete }),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                handleCloseDeleteDialog();
                fetchData(); // Refresh the data after deleting
            })
            .catch((error) => console.error(`Error deleting item from ${tableName}:`, error));
    };
    const handleClickOpenDeleteDialog = (itemId) => {
        setItemToDelete(itemId);
        setDeleteDialogOpen(true);
    };
    const handleCloseDeleteDialog = () => {
        setItemToDelete(null);
        setDeleteDialogOpen(false);
    };
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };
    let sortedData = [...data];
    if (sortConfig !== null) {
        sortedData.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    }
    return (
        <>
            <Typography variant="h4" className={classes.header}>{tableName.charAt(0).toUpperCase() + tableName.slice(1)} Manager</Typography>
            <div style={{ overflowX: 'auto', height: '450px' }}>
                <Table >
                    <TableHead className={classes.stickyHeader}>
                        <TableRow>
                            {columns.map((column) => (
                                (
                                    <TableCell
                                        className={classes.tableHeader}
                                        key={column.name}
                                        onClick={() => requestSort(column.name)}
                                    >
                                        {column.display_name ? column.display_name : column.name}
                                        {sortConfig && sortConfig.key === column.name && (sortConfig.direction === 'ascending' ? ' 🔽' : ' 🔼')}
                                    </TableCell>
                                )
                            ))}
                            <TableCell className={classes.tableHeader}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map(row => (
                            <React.Fragment key={row.oid}>
                                {editingRow !== row.oid ? (
                                    <TableRow>
                                        {columns.map(column => (
                                             (
                                                <TableCell key={column.name}>

                                                    {column.controlType === 'list' ? (
                                                        (column.options.find(option => option.id === row[column.name]) || {}).name
                                                    ) : column.controlType === 'checkbox' ? (
                                                         row[column.name] === 1 ? (
                                                            'YES'
                                                         ) : ('NO')
                                                    ) :  column.type === 'date' ? (
                                                        new Date(row[column.name]).toLocaleDateString()
                                                    ) : (row[column.name])}
                                                   
                                                </TableCell>
                                            )   
                                        ))}
                                        <TableCell>
                                            <IconButton onClick={() => handleEdit(row)}><EditIcon /></IconButton>
                                            <IconButton onClick={() => handleClickOpenDeleteDialog(row.oid)}><DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <TableRow>
                                        {columns.map((column, index) => (
                                            
                                             (
                                                <TableCell key={index}>
                                                    {column.controlType === 'textbox' ? (
                                                        <TextField
                                                            name={column.name}
                                                            value={formData[column.name]}
                                                            onChange={handleChange}
                                                            type={column.type}
                                                            InputProps={{
                                                                readOnly: column.name === 'oid',
                                                                ...(column.maxLength && { maxLength: column.maxLength }),
                                                            }}
                                                            required={column.required}
                                                        />
                                                    ) : column.controlType === 'list' ? (
                                                        <Select
                                                            name={column.name}
                                                            value={formData[column.name]}
                                                            onChange={handleChange}
                                                            required={column.required}
                                                        >
                                                            {column.options.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    ) : column.controlType === 'checkbox' ? (
                                                        <Checkbox
                                                            name={column.name}
                                                            checked={!!formData[column.name]} // convert to Boolean
                                                            onChange={handleChange}
                                                            color="primary"
                                                        />
                                                    ) : null}
                                                </TableCell>
                                            )
                                        ))}

                                        <TableCell>
                                            <IconButton onClick={handleSave}>
                                                <SaveIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>

                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <Dialog
                open={deleteDialogOpen}  onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{`Delete ${tableName}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GenericEdit;

