import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Checkbox, Table, InputLabel, Link, TableBody, TableCell, Button, TableContainer, TableHead, TableRow, IconButton, TextField, Select, MenuItem, Paper, CircularProgress, Popover, Tooltip, Dialog, DialogContent, DialogActions } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CentralCalls from '../centralCalls';
import { makeStyles } from '@mui/styles';
import { faEdit, faSort, faMinus, faArrowDown, faPlus, faRefresh, faList, faChevronUp, faChevronDown, faSave, faSortUp, faFilter, faSortDown, faDollarSign, faTimes, faCheck, faUndo, faClipboard, faPlayCircle, faPause, faEye, faTimesCircle, faTrash, faBars, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { blueGrey } from '@mui/material/colors';
import DeleteConfirmationPopup from './DeleteConfirmationPopup';
import SubRow from './SubRow';
import SubRows from './SubRow';
import PoItems from './po/PoItems';
const useStyles = makeStyles(theme => ({
    divStyle: {
        borderRadius: '10px',
        padding: '0px',
        margin: '0px'

    },
    outline: {
        border: 'solid black thin'
    },
    tableContainer: {
        maxHeight: 475,
        background: 'inherit'
    },
    table: {
        minWidth: 650

    },
    header: {
        position: 'sticky',
        top: 0,
        padding: 5,
        margin: 0,
        //backgroundColor: 'primary',
        backgroundColor: '#0b426b',
        //color: 'white',
        zIndex: 10,
        fontWeight: 'bolder',
        fontSize: '14px'
    },
    smallIconButton: {
        //padding: theme.spacing(0),
        fontSize: "1.2rem",
        paddingRight: "10px",
        height: "10px"// adjust this value as needed
    },
    enabled: {
        opacity: 1,
        color: blueGrey
    },
    row: {
        '&:nth-of-type(odd)': {
            // backgroundColor: theme.palette.action.hover,
            height: '20px',
            padding: '0'
        },
        '&.disabled': {
            opacity: 0.5 /* Reduced opacity to make it appear disabled */
        }
    },
    cell: {
        height: '15px',
        padding: '0px 10px 0px 10px',
        fontSize: '14px'
    },
    stickHeader: {
        position: 'sticky',
        top: 300,
        zIndex: 1,
        backgroundColor: '#0b426b',
        // backgroundColor: theme?.header_back_color ? theme.header_back_color : '#3f51b5',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
    },
    fixedHeader: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#0b426b',
        color: theme?.header_text_color ? theme.header_text_color : 'white',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px 10px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.3)'
    },
    searchTextField: {
       
        float: 'right', // Pushes the TextField to the right within its parent div
        borderRadius: '50px', // Makes the corners rounded
        height: '25px', // Sets the height to about half of the standard height
        '& .MuiInputBase-root': {
            height: '25px', // Ensures the input area is also reduced in height
            fontSize: '0.875rem', // Optional: Adjust font size if needed
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '50px', // Ensures the border is also rounded
            },
        },
    }
}));
const JSONTableEditor = ({ procedureDetails, onSave, sortOrder, customFunctions, hideEdit, allowDelete, limitHeight = true, showCheckedRows = false, handleSetCheckedRows, includeSubRows = false, subRowComponentName = '', isSubRowComponent = false }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [editingRow, setEditingRow] = useState(-1);
    const [searchInput, setSearchInput] = useState('');
    const [deleteRow, setDeleteRow] = useState({});
    const [formData, setFormData] = useState({});
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [theme, setTheme] = useState({});
    const [tableName, setTableName] = useState("");
    const [options, setOptions] = useState({});
    const [headerText, setHeaderText] = useState("");
    const [openSubRowId, setOpenSubRowId] = useState(0);
    const [sortingCriteriaList, setSortingCriteriaList] = useState({});
    //const [checkedRows, setCheckedRows] = useState([]);
    const [checkedOids, setCheckedOids] = useState([]);
    const [filters, setFilters] = useState({});
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentFilteringColumn, setCurrentFilteringColumn] = useState('');
    const [refreshTableKey, setRefreshTableKey] = useState(0);
    const [sortOrderOnPage, setSortOrderOnPage] = useState('asc'); // 'asc' or 'desc'
    const access_level = localStorage.getItem('access');
    const [subRows, setSubRows] = useState({});

    useEffect(() => {
        setLoading(true);
        const { procedureName, parameters } = procedureDetails;
        setSubRows([{ item_desc: 'testOne', cost: 34, po_oid: 1127 }, { item_desc: 'testTwo', cost: 45, po_oid: 1127 }, { item_desc: 'testThree', cost: 45.56, po_oid: 1127 }])
        const apiUrl = parameters && Object.keys(parameters).length > 0
            ? '/api/call-stored-procedureWithParam3'
            : '/api/call-stored-procedure';
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ procedureName, param: JSON.stringify(parameters) }),
        })
            .then(response => response.json())
            .then(result => {
                setColumns(sortColumnOrder(result.columns));
                setColumns(prevColumns => [...prevColumns, {name:'combinedColumn', read_only: true, required:false, hide:true }]);
                if (sortOrder && result.data) {
                    const sortedData = sortData(result.data, sortOrder);
                    setData(addConcatColumn(sortedData, result.columns));
                }
                else {
                    setData(result.data);
                }
                setTableName(result.table_name);
                setOptions(result.options);
                setTheme(result.theme);
                setHeaderText(result.header_text);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
                setLoading(false);
                alert('Error updating data.Reach out for assistance')
            });

    }, [procedureDetails, refreshTableKey, sortOrder]);
    const handleEdit = (row) => {
        setEditingRow(row.oid);
        setFormData(row);
    };
    const addConcatColumn = (data, columns) => {
        // Filter columns to include only those where `hide` is `null` or `false`
        const visibleColumns = columns.filter(col => col.hide !== true);
        return data.map(row => {
            // Only concatenate values from the visible columns
            const combinedValue = visibleColumns.map(col => row[col.name]).join(' ');
            return {
                ...row,
                combinedColumn: combinedValue,
            };
        });
    };
    const handleDelete = (row) => {
        setDeleteRow(row);
        setDeleteConfirmOpen(true);
    };
    const handleHideDelete = () => {
        setDeleteRow({});
        setDeleteConfirmOpen(false);
    }
    const handleDeleteRow = async () => {
        await CentralCalls.upsertData({ oid: deleteRow.oid, deleted: 1 }, tableName, 'update');
        setDeleteRow({});
        setDeleteConfirmOpen(false);
        setRefreshTableKey(prevKey => prevKey + 1);
    }
    const handleSave = () => {
        const formattedData = { ...formData };
        const sendData = { ...formData };
        columns.forEach(column => {
            if (column.controlType === 'checkbox') {
                formattedData[column.name] = formattedData[column.name] ? 1 : 0;
                sendData[column.name] = sendData[column.name] ? 1 : 0;
            }
            else if (column.controlType === 'multiselect') {
                // Format as JSON array string
                sendData[column.name] = JSON.stringify(sendData[column.name]);
            }
        });
        const readOnlyColumns = columns.filter(column => column.read_only || column.ignore).map(column => column.name);
        readOnlyColumns.forEach(columnName => {
            if (columnName !== 'oid') {
                delete sendData[columnName];
            }
        });
        // Make API call first, update local state after successful response
        fetch('/api/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tableName: tableName,
                updateObj: sendData
            })
        }).then(response => {
            if (response.ok) {
                columns.forEach(column => {
                    if (column.controlType === 'multiselect' && !column.read_only === true) {
                        formattedData[column.name] = JSON.parse(sendData[column.name]);
                    }
                });
                const updatedData = data.map(row => row.oid === formData.oid ? formattedData : row);
                setData(updatedData);
                setEditingRow(-1);
                setFormData({});
            } else {
                throw new Error('Update failed!');
            }
        }).catch(error => {
            console.error(error);
        });

        if (onSave) {
            onSave(formData);
        }
    };
    const handleChange = (event) => {
        // const { name, value, type, checked } = event.target;
        // setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
        } else if (type === 'select-multiple') {
            setFormData({ ...formData, [name]: [...event.target.selectedOptions].map(option => option.value) });
        } else if (type === 'date') {
            //For dates turn '' to null so you don't get a default date
            const newValue = value === '' ? null : value;
            setFormData({ ...formData, [name]: newValue });
        }
        else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const sortData = (data, sortOrder) => {
        return [...data].sort((a, b) => {
            for (let key in sortOrder) {
                // Get values for comparison
                const valueA = a[key];
                const valueB = b[key];
                // Handle null, undefined, or empty string values
                if (valueA == null || valueA === undefined || valueA === '') {
                    if (valueB == null || valueB === undefined || valueB === '') {
                        // Both values are null/undefined/empty, continue to the next key
                        continue;
                    }
                    // Only valueA is null/undefined/empty, place it after valueB
                    return sortOrder[key] === 'asc' ? 1 : -1;
                }

                if (valueB == null || valueB === undefined || valueB === '') {
                    // Only valueB is null/undefined/empty, place it after valueA
                    return sortOrder[key] === 'asc' ? -1 : 1;
                }

                // Compare non-null, non-undefined, and non-empty string values
                if (valueA < valueB) {
                    return sortOrder[key] === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortOrder[key] === 'asc' ? 1 : -1;
                }
            }
            return 0;
        });
    };
    const convertToCSV = (data) => {
        const csvRows = [];
        // Get the headers
        const headers = columns.map(column => column.display_name);
        csvRows.push(headers.join(','));

        // Loop over the rows
        for (const row of data) {
            const values = columns.map(column => {
                const escaped = ('' + row[column.name]).replace(/"/g, '\\"'); // Escape double quotes
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    };
    const handleSearchInputChange = (event) => {
        const currentText = event.target.value;
        setSearchInput(currentText);
        //handleFilterChange('combinedcolumn', currentText);
      };
    const downloadCSV = (data) => {
        if (data) {
            const csvData = convertToCSV(data);
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = `${tableName}.csv`;
            link.href = url;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    };

    const sortColumnOrder = (columns) => {
        columns.sort((a, b) => {
            if (a.column_sort === undefined && b.column_sort === undefined) return 0;  // If both don't have the key, order remains unchanged.
            if (a.column_sort === undefined) return 1;  // If a doesn't have the key, it goes to the end.
            if (b.column_sort === undefined) return -1; // If b doesn't have the key, it goes to the end.
            return a.column_sort - b.column_sort; // Otherwise, compare based on the key.
        });
        return columns;
    }
    const handleHeaderClick = (columnName) => {
        // Create a copy of the sorting criteria object
        const updatedSortingCriteria = { ...sortingCriteriaList };

        // Determine the current sorting order for the clicked column
        const currentOrder = updatedSortingCriteria[columnName];

        // Toggle the sorting order ('asc' to 'desc' or vice versa) or default to 'asc'
        updatedSortingCriteria[columnName] = currentOrder === 'asc' ? 'desc' : 'asc';

        // Set the updated sorting criteria object
        setSortingCriteriaList(updatedSortingCriteria);
    };
    const handleCheckboxChange = (oid) => {
        setCheckedOids((prevCheckedOids) => {
            let newCheckedOids;
            if (prevCheckedOids.includes(oid)) {
                // If it is, remove it (uncheck)
                newCheckedOids = prevCheckedOids.filter(id => id !== oid);
            } else {
                // If it isn't, add it (check)
                newCheckedOids = [...prevCheckedOids, oid];
            }

            // Send the updated checked rows directly to handleSetCheckedRows
            const updatedCheckedRows = finalData.filter(item => newCheckedOids.includes(item.oid));
            handleSetCheckedRows(updatedCheckedRows);

            return newCheckedOids;
        });
    };

    const handleToggleAll = () => {
        if (allChecked) {
            // Uncheck all
            setCheckedOids([]);
            handleSetCheckedRows([]);
        } else {
            // Check all
            const allOids = finalData.map(row => row.oid);
            setCheckedOids(allOids);
            handleSetCheckedRows(finalData);
        }
        setAllChecked(!allChecked);

    };
    const clearSortingAndFilters = () => {
        setSortingCriteriaList({});
        setFilters({});// Clear the sorting criteria
        setSearchInput('');
        // Reset any other filter states if needed
    };
    const handleFilterClick = (event, columnName) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setPopoverOpen(true);
        setCurrentFilteringColumn(columnName);
    };
    const findDuplicateOids = (finalData) => {
        // Create a map to track the count of each oid
        const oidCountMap = finalData.reduce((acc, row) => {
            acc[row.oid] = (acc[row.oid] || 0) + 1;
            return acc;
        }, {});
        const duplicateRows = finalData.filter(row => oidCountMap[row.oid] > 1);
        return duplicateRows;
    };
    const handleFilterChange = (columnName, value) => {
        setFilters(prevFilters => {
            const currentFilters = { ...prevFilters };
            if (currentFilters[columnName]?.includes(value)) {
                currentFilters[columnName] = currentFilters[columnName].filter(v => v !== value);
            } else {
                currentFilters[columnName] = [...(currentFilters[columnName] || []), value];
            }
            return currentFilters;
        });
    };
    const handleSubRowClick = (oid) => {
        setOpenSubRowId(oid);
    }
    const handleClearSubRow = (oid) => {
        setOpenSubRowId(0);
    }
    function getDistinctValuesForColumn(columnName) {
        const column = columns.find(col => col.name === columnName);
        if (!column) return [];

        if (column.controlType === 'select') {
            const listName = column.list_name;
            const distinctBackendValues = [...new Set(sortedData.map(row => row[columnName]))];
            return distinctBackendValues.map(value => {
                const option = options[listName].find(opt => opt.oid === value);
                return { display: option?.name || '', value: option?.oid || '' };
            });
        }
        // For non-select columns, just return distinct values
        return [...new Set(sortedData.map(row => row[columnName]))];
    }
    const sortedData = data?.length > 0 && Object.keys(sortingCriteriaList).length
        ? sortData(data, sortingCriteriaList)
        : data;

    const applyFiltersOG = (data) => {
        if (data) {
            let filteredData = [...data];
            for (const columnName in filters) {
                filteredData = filteredData.filter(row => filters[columnName].includes(row[columnName]));
            }
            return filteredData;
        }
        else {
            return data;
        }
    };
    const applyFilters = (data) => {
        if (!data) return data;
    
        let filteredData = [...data];
        // Apply filters for specific columns
        for (const columnName in filters) {
            filteredData = filteredData.filter(row => filters[columnName].includes(row[columnName]));
        }
        // Check if inputSearchText is not null or empty and filter based on combinedcolumn
        if (searchInput && searchInput.trim() !== '' && filteredData.length > 0  && 'combinedColumn' in filteredData[0]) {
            
            filteredData = filteredData.filter(row => row.combinedColumn.toLowerCase().includes(searchInput.toLowerCase()));

        }
    
        return filteredData;
    };
    const finalData = applyFilters(sortedData);

    return (
        <div className={classes.divStyle}>
            {!isSubRowComponent && (
                <>
                    {!minimized && (<IconButton className={classes.smallIconButton} color="primary" aria-label="Click To Collapse" onClick={() => { setMinimized(!minimized); }} size="small" title="Click To Collapse" >
                        <FontAwesomeIcon icon={faChevronUp} />
                    </IconButton>
                    )}
                    {minimized && (<IconButton className={classes.smallIconButton} color="primary" aria-label="Click To Expand" onClick={() => { setMinimized(!minimized); }} size="small" title="Click To Expand" >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </IconButton>)}
                    <IconButton className={classes.smallIconButton} color="primary" aria-label="Reset Filters" onClick={(clearSortingAndFilters)} size="small" title="Reset Filters" >
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </IconButton>
                    <IconButton className={classes.smallIconButton} color="primary" aria-label="Refresh Data" onClick={() => { setRefreshTableKey(prevKey => prevKey + 1) }} size="small" title="Refresh Data" >
                        <FontAwesomeIcon icon={faRefresh} />
                    </IconButton>
                    <IconButton className={classes.smallIconButton} color="primary" aria-label="Export" onClick={() => downloadCSV(finalData)} size="small" title="Export" >
                        <FontAwesomeIcon icon={faFileExcel} />
                    </IconButton>
                    <TextField
                        className={classes.searchTextField}
                        label="Search"
                        value={searchInput}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        size="small" 
                        onChange={handleSearchInputChange}
                    />
                </>)}

            <div style={{ backgroundColor: theme?.main_back_color ? theme.main_back_color : 'white' }}>
                <TableContainer component={Paper} className={limitHeight ? classes.tableContainer : null}>
                    <Table className={classes.table}>
                        <TableHead className={`${classes.row} ${(!minimized) ? '' : 'disabled'}`}>
                            {headerText && (
                                <TableRow className={limitHeight ? classes.stickHeader : null}>
                                    <TableCell
                                        className={classes.header}
                                        colSpan={columns.filter((column) => !column.hide).length + 1}
                                        style={{ color: 'black', paddingLeft: '25px', padding: '5px' }} >
                                        <label
                                            style={{ fontSize: '1.2rem', fontWeight: 'bold', margin: '0px 0', padding: '0px', textTransform: 'uppercase' }}>
                                            {headerText}
                                        </label>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow style={{ position: 'sticky', top: 0, zIndex: 9, backgroundColor: '#0b426b' }} >
                                {includeSubRows && (<TableCell>
                                    Open
                                </TableCell>)}
                                {showCheckedRows && (<TableCell>
                                    <Link style={{ color: 'white', textDecoration: 'underline' }} component="button" variant="body2" onClick={handleToggleAll}>
                                        {allChecked ? "Uncheck" : "Check"}
                                    </Link>
                                </TableCell>)}

                                {columns.map(column => (
                                    !column.hide && (
                                        <TableCell
                                            key={column.name}
                                            className={classes.header}
                                            style={{ color: theme?.header_text_color ? theme.header_text_color : 'white', margin: '0px', cursor: 'pointer' }}
                                            onClick={() => handleHeaderClick(column.name)}
                                        >
                                            {column.display_name}
                                            {(!column.ignore_filter && !theme?.no_filters) && (
                                                <IconButton
                                                    aria-label="filter"
                                                    onClick={(e) => handleFilterClick(e, column.name)}
                                                    size="small">
                                                    <FontAwesomeIcon icon={faFilter} />
                                                </IconButton>
                                            )}
                                            {sortingCriteriaList[column.name] && (
                                                sortingCriteriaList[column.name] === 'asc' ? (
                                                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: '4px' }} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: '4px' }} />
                                                )
                                            )}
                                        </TableCell>
                                    )))}
                                <TableCell className={classes.header} style={{ color: theme?.header_text_color ? theme.header_text_color : 'white' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!minimized && finalData && finalData.map(row => (
                                <React.Fragment>
                                    <TableRow key={row.oid} className={`${classes.row} ${(row.enabled === undefined || row.enabled) ? '' : 'disabled'}`} style={{ backgroundColor: row.row_back_color ? row.row_back_color : 'inherit' }}>
                                        {includeSubRows && (
                                            <TableCell>
                                                {openSubRowId === row.oid ? (
                                                    <IconButton
                                                        aria-label="filter"
                                                        onClick={() => handleClearSubRow(row.oid)}
                                                        size="small"
                                                    >
                                                        <FontAwesomeIcon icon={faMinus} />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        aria-label="filter"
                                                        onClick={() => handleSubRowClick(row.oid)}
                                                        size="small"
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        )}

                                        {showCheckedRows && checkedOids && (<TableCell>
                                            <Checkbox
                                                checked={checkedOids.includes(row.oid)}
                                                onChange={() => handleCheckboxChange(row.oid)}
                                            />
                                        </TableCell>)}

                                        {columns.map(column => (
                                            !column.hide && (
                                                <TableCell key={column.name} className={classes.cell} style={{ color: row.row_text_color ? row.row_text_color : 'black' }} >
                                                    {editingRow === row.oid && !column.read_only ? (
                                                        <>
                                                            {column.controlType === 'checkbox' && (
                                                                <Checkbox
                                                                    name={column.name}
                                                                    checked={!!formData[column.name]}
                                                                    onChange={handleChange}
                                                                />
                                                            )}
                                                            {column.controlType === 'date' && (
                                                                <TextField
                                                                    name={column.name}
                                                                    type="date"
                                                                    pattern={column.format ? column.format : '\\d{2}/\\d{2}/\\d{4}'}
                                                                    value={formData[column.name] || ''}
                                                                    onChange={handleChange}
                                                                />
                                                            )}
                                                            {column.controlType === 'select' && (
                                                                <Select
                                                                    name={column.name}
                                                                    value={formData[column.name] || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    {options[column.list_name] &&
                                                                        options[column.list_name]
                                                                            .slice()
                                                                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort options inline
                                                                            .map((option) => (
                                                                                <MenuItem key={option.oid} value={option.oid}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                </Select>
                                                            )}
                                                            {column.controlType === 'multiselect' && (
                                                                <Select
                                                                    name={column.name}
                                                                    multiple
                                                                    value={formData[column.name] || []}
                                                                    onChange={handleChange}
                                                                >
                                                                    {options[column.list_name] &&
                                                                        options[column.list_name]
                                                                            .slice()
                                                                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort options inline
                                                                            .map((option) => (
                                                                                <MenuItem key={option.oid} value={option.oid}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                </Select>
                                                            )}
                                                            {column.controlType === 'text' && (
                                                                <TextField
                                                                    name={column.name}
                                                                    value={formData[column.name] || ''}
                                                                    onChange={handleChange}
                                                                    className={classes.outline}
                                                                />
                                                            )}
                                                            {(column.controlType === 'number' || column.controlType === 'money') && (
                                                                <TextField

                                                                    name={column.name}
                                                                    value={formData[column.name] || ''}
                                                                    type="number"
                                                                    onChange={handleChange}
                                                                    className={classes.outline}
                                                                />
                                                            )}
                                                        </>
                                                    ) : (
                                                        column.controlType === 'select' ? (
                                                            row[column.name] ? (
                                                                (options[column.list_name]?.find(option => option.oid === row[column.name]) || {}).name || ''
                                                            ) : ''
                                                        ) :
                                                            column.controlType === 'multiselect' ? (
                                                                row[column.name] ? (
                                                                    row[column.name].map(id =>
                                                                        (options[column.list_name]?.find(option => option.oid === id) || {}).name || ''
                                                                    ).join(', ')
                                                                ) : ''
                                                            ) :
                                                                column.controlType === 'date' ? (
                                                                    column.format ? CentralCalls.formatDate(row[column.name], column.format) : CentralCalls.formatDate(row[column.name], '\\d{2}/\\d{2}/\\d{4}')
                                                                ) :
                                                                    column.controlType === 'money' ? (
                                                                        row[column.name] !== null && row[column.name] !== undefined
                                                                            ? CentralCalls.formatTotal(row[column.name])
                                                                            : ''
                                                                    ) :
                                                                        // Not editing and cell's controlType is not select
                                                                        column.controlType === 'checkbox' ? (
                                                                            <Checkbox checked={!!row[column.name]} disabled />
                                                                        ) : column.controlType === 'text' ? (
                                                                            <span>
                                                                                {column.hover && row[column.name] ? (
                                                                                    <Tooltip title={row[column.name]}>
                                                                                        <span>
                                                                                            {row[column.name] && column.max_length !== null ? (
                                                                                                <span>
                                                                                                    {row[column.name].length > column.max_length
                                                                                                        ? row[column.name].substring(0, column.max_length) + '...'
                                                                                                        : row[column.name]}
                                                                                                </span>
                                                                                            ) : (
                                                                                                <span>{row[column.name]}</span>
                                                                                            )}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                ) : (
                                                                                    <span>
                                                                                        {row[column.name] && column.max_length !== null ? (
                                                                                            <span>
                                                                                                {row[column.name].length > column.max_length
                                                                                                    ? row[column.name].substring(0, column.max_length) + '...'
                                                                                                    : row[column.name]}
                                                                                            </span>
                                                                                        ) : (
                                                                                            <span>{row[column.name]}</span>
                                                                                        )}
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        ) : (
                                                                            row[column.name]
                                                                        )
                                                    )}
                                                </TableCell>
                                            )))}
                                        <TableCell style={{ padding: '5px' }}>
                                            {editingRow === row.oid ? (
                                                <IconButton className={classes.smallIconButton} color="primary" aria-label="save" onClick={handleSave} size="small">
                                                    <FontAwesomeIcon icon={faSave} />
                                                </IconButton>
                                            ) : (
                                                <>
                                                    {!hideEdit && (
                                                        <IconButton
                                                            className={classes.smallIconButton}
                                                            color="primary"
                                                            aria-label="edit"
                                                            onClick={() => handleEdit(row)}
                                                            size="small"
                                                            disabled={((row.enabled !== undefined && !row.enabled) && access_level !== 'full')}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </IconButton>
                                                    )}
                                                    {allowDelete && (
                                                        <IconButton
                                                            className={classes.smallIconButton}
                                                            color="primary"
                                                            aria-label="edit"
                                                            onClick={() => handleDelete(row)}
                                                            size="small"
                                                            disabled={((row.deletable !== undefined && !row.deletable))}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </IconButton>
                                                    )}
                                                    {customFunctions && customFunctions.map(customFunction => (
                                                        (!customFunction.displayCheck || customFunction.displayCheck(row)) && (
                                                            <IconButton
                                                                disabled={((row.enabled !== undefined && !row.enabled) && access_level !== 'full') && !customFunction.alwaysEnable}
                                                                className={`${classes.smallIconButton}`}

                                                                key={customFunction.type}
                                                                size="small"
                                                                color="primary"
                                                                aria-label={customFunction.type}
                                                                onClick={() => customFunction.func(row)}
                                                            >
                                                                {customFunction.type === 'revert' ? (
                                                                    <FontAwesomeIcon icon={faUndo} title={customFunction.display_name} />
                                                                ) : customFunction.type === 'approval' ? (
                                                                    <FontAwesomeIcon icon={faClipboard} title={customFunction.display_name} />
                                                                ) : customFunction.type === 'hold' ? (
                                                                    <FontAwesomeIcon icon={faPlayCircle} title={customFunction.display_name} />
                                                                ) : customFunction.type === 'pause' ? (
                                                                    <FontAwesomeIcon icon={faPause} title={customFunction.display_name} />
                                                                ) : customFunction.type === 'cancel' ? (
                                                                    <FontAwesomeIcon icon={faTimes} title={customFunction.display_name} />
                                                                ) : customFunction.type === 'overview' ? (
                                                                    <FontAwesomeIcon icon={faEye} title={customFunction.display_name} />
                                                                ) : customFunction.type === 'money' ? (
                                                                    <FontAwesomeIcon icon={faDollarSign} title={customFunction.display_name} />
                                                                ) : customFunction.type === 'list' ? (
                                                                    <FontAwesomeIcon icon={faList} title={customFunction.display_name} />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faCheck} title={customFunction.display_name} />
                                                                )}
                                                            </IconButton>
                                                        )
                                                    ))}
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    {includeSubRows && openSubRowId === row.oid && (
                                        <TableRow style={{ borderTop: 'solid', borderBottom: 'solid' }}>

                                            <TableCell colSpan={columns.length + 2}>

                                                <div style={{}}>
                                                    <FontAwesomeIcon icon={faArrowDown} style={{ float: 'left', marginTop: '-10px', marginLeft: '150px' }} />
                                                </div>
                                                <div style={{ floatRight: 'right' }}>
                                                    <FontAwesomeIcon icon={faArrowDown} style={{ float: 'right', marginTop: '-10px', marginRight: '150px' }} />
                                                </div>
                                                {/* <label><faArrowDown></faArrowDown></label> */}
                                                <PoItems poOid={row.oid} />
                                            </TableCell>l
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <Popover
                style={{ paddingRight: '15px' }}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={() => {
                    setPopoverOpen(false);
                    setAnchorEl(null);
                    setCurrentFilteringColumn('');
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}  >
                <div>
                    {currentFilteringColumn && currentFilteringColumn !== '' && getDistinctValuesForColumn(currentFilteringColumn)?.sort((a, b) => a.localeCompare(b))
                        .map(distinctValue => (
                            <div key={distinctValue}>
                                <Checkbox
                                    checked={filters[currentFilteringColumn]?.includes(distinctValue) || false}
                                    onChange={() => handleFilterChange(currentFilteringColumn, distinctValue)} />
                                {distinctValue}
                            </div>
                        ))}
                </div>
            </Popover>
            <Dialog fullWidth={false} open={deleteConfirmOpen} onClose={() => handleHideDelete()}>
                <DialogContent>
                    <DeleteConfirmationPopup displayText='Delete Record?' onDelete={() => handleDeleteRow()} onCancel={() => handleHideDelete()}></DeleteConfirmationPopup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog >
        </div>
    );
};
export default JSONTableEditor;