import React, { useState, useEffect } from 'react';
import CentralCalls from '../../centralCalls';
import {  Select, MenuItem } from '@mui/material';
import WipLink from '../WipLink';
import { makeStyles } from '@mui/styles';
import PhaseLink from '../PhaseLink';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    select :{

    },
    customDivider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#e0e0e0',  // Adjust the color as needed
        margin: theme.spacing(2, 0),  // Adjust for desired spacing above and below
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    datePickerContainer: {
        display: 'flex',
        alignItems: 'center', // To align the items vertically in the center
        gap: theme.spacing(1)  // Spacing between the text and the input
    },
    cell: {
        border: '1px solid white',
    },
    verticallyCentered: {
        verticalAlign: 'middle',
    },
    evenRow: {
        backgroundColor: '#f2f2f2',
        '& td': {
            border: '1px solid #f2f2f2',
        },
    },
    tableHeader: {
        textAlign: 'left',  // Apply left alignment for headers
    }
}));

function EmployeeSchedule() {
    const [scheduleData, setScheduleData] = useState({});
    const [plumberList, setPlumberList] = useState([]);
    const [selectedPlumbers, setSelectedPlumbers] = useState([]);
    const classes = useStyles();
    useEffect(() => {

        CentralCalls.fetchTableData('employee', {'inactive': 0}).then(response => {
            const sortedResponse = response.slice().sort((a, b) => a.name.localeCompare(b.name));
            setPlumberList(sortedResponse);
        });
    }, []);
    
    const fetchScheduleData = async () => {
        const procedureName = 'ui_sp_getPlumberSchedule';
        const param = {
            employee_oids: selectedPlumbers,
        };
        try {
            const result = await CentralCalls.callStoredProcedureWithParams(procedureName, param);
            setScheduleData(result || {});
        } catch (error) {
            console.error('Failed to fetch schedule data:', error);
        }
    };

    useEffect(() => {
        fetchScheduleData(); // Call fetchScheduleData when selectedPlumbers change
    }, [selectedPlumbers]); // Listen for changes in selectedPlumbers only

    return (
        <div className={classes.root}>
            <h1>Plumber Schedule</h1>
            <div className={classes.datePickerContainer}>
                <h4>Select Employee(s)</h4>
                <Select className='select'
                    name="selectedPlumbers"
                    multiple
                    value={selectedPlumbers}
                    onChange={(e) => setSelectedPlumbers(e.target.value)}
                >
                    {plumberList.map(plumber => (
                        <MenuItem key={plumber.oid} value={plumber.oid}>
                            {plumber.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className={classes.customDivider}></div>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th className={classes.tableHeader}>Date</th>
                        <th className={classes.tableHeader}>Plumber(s)</th>                        
                        <th className={classes.tableHeader}>Builder</th>
                        <th className={classes.tableHeader}>Neighborhood</th>
                        <th className={classes.tableHeader}>Lot Num</th>
                        <th className={classes.tableHeader}>Phase Type</th>
                        <th className={classes.tableHeader}>Comments</th>
                        <th className={classes.tableHeader}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(scheduleData).map((scheduledDate, idx) => {
                        const phases = scheduleData[scheduledDate];
                        return (
                            <React.Fragment key={idx}>
                                <tr className={idx % 2 === 0 ? classes.evenRow : ''}>
                                    <td rowSpan={phases.length} className={`${classes.verticallyCentered} ${classes.cell}`}>
                                        {CentralCalls.formatDate(scheduledDate, 'short')}
                                    </td>
                                    <td className={classes.cell}>{phases[0].plumbers}</td>                                    
                                    <td className={classes.cell}>{phases[0].builder}</td>
                                    <td className={classes.cell}>{phases[0].neighborhood}</td>
                                    <td className={classes.cell}>{phases[0].lot_num}</td>
                                    <td className={classes.cell}>{phases[0].phase_type}</td>
                                    <td className={classes.cell}>{phases[0].comments}</td>
                                    <td><WipLink lot_oid={phases[0].lot_oid} ></WipLink>
                                    <PhaseLink phase_type={phases[0].phase_type_id} ></PhaseLink></td>
                                    
                                </tr>
                                {phases.slice(1).map((phase, innerIdx) => (
                                    <tr key={innerIdx} className={idx % 2 === 0 ? classes.evenRow : ''}>
                                        <td className={classes.cell}>{phase.plumbers}</td>
                                        
                                        <td className={classes.cell}>{phase.builder}</td>
                                        <td className={classes.cell}>{phase.neighborhood}</td>
                                        <td className={classes.cell}>{phase.lot_num}</td>
                                        <td className={classes.cell}>{phase.phase_type}</td>
                                        <td className={classes.cell}>{phase.comments}</td>
                                        <td><WipLink lot_oid={phase.lot_oid} ></WipLink>
                                        <PhaseLink phase_type={phase.phase_type_id} ></PhaseLink></td>
                                        
                                    </tr>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default EmployeeSchedule;
