import React, { useState, useMemo, useEffect } from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle, TextField, DialogContent, Button, DialogActions, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { faPlus, faRefresh, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import JSONTableEditor from '../JSONTableEditor';
import Autocomplete from '@mui/material/Autocomplete';
// Define the component and destructure the prop from the component's props
const WipInspectionSection = ({ selectedLotOid }) => {
    const [addPhaseDialogOpen, setAddPhaseDialogOpen] = useState(false);
    const [refreshPhaseKey, setRefreshPhaseKey] = useState(4000);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [PunchDialogOpen, setPunchDialogOpen] = useState(false);
    const [punchPhaseType, setPunchPhaseType] = useState('Select');
    const [plumberList, setPlumberList] = useState([]);
    const [selectedPlumbers, setSelectedPlumbers] = useState([]);
    const phaseTypes = [
        { id: 9, name: 'Select', name_id: 'Select' },
        { id: 9, name: 'Slab', name_id: 'slab' },
        { id: 10, name: 'Rough', name_id: 'rough' },
        { id: 11, name: 'Drain Tubs', name_id: 'draintubs' },
        { id: 12, name: 'Set Out', name_id: 'setout' },
        { id: 13, name: 'Underground', name_id: 'underground' },
        { id: 14, name: 'Warranty', name_id: 'warranty' },
        { id: 15, name: 'Slab Quality Check', name_id: 'slab_qc' },
        { id: 16, name: 'Rough Quality Check', name_id: 'rough_qc' },
        { id: 17, name: 'Setout Quality Check', name_id: 'setout_qc' },
        { id: 18, name: 'Slab Inspection', name_id: 'slab_inspection' },
        { id: 19, name: 'Underground Inspection', name_id: 'underground_inspection' },
        { id: 20, name: 'Rough Inspection', name_id: 'rough_inspection' },
        { id: 21, name: 'Setout Inspection', name_id: 'setout_inspection' },
    ];
    useEffect(() => {
        CentralCalls.fetchTableDataNoWhere('employee').then(response => {
            const sortedResponse = response.slice().sort((a, b) => a.name.localeCompare(b.name));
            setPlumberList(sortedResponse);
        });
    }, []);
    //const [selectedPhaseRow, setSelectedPhaseRow] = useState(null);
    const [selectedPhase, setSelectedPhase] = useState(null);
    const addNewPoToPhase = async () => {
        //await CentralCalls.upsertData({ 'phase_oid': selectedPhase}, 'po', 'insert');
        //setRefreshIconKey(prevKey => prevKey + 1);
        setStatusDialogOpen(false);
        //setAddPODialogOpen(false);
    }
    const addPoClick = async (phaseRow) => {
        setSelectedPhase(phaseRow.phase_oid);
        setStatusDialogOpen(true);
        //setSelectedPhaseRow(phaseRow);
    }
    const handleSavePhaseDetails = async (slabRow) => {
        CentralCalls.upsertData({ 'oid': slabRow.phase_oid, 'date_scheduled': slabRow.date_scheduled, 'builder_date': slabRow.builder_date, 'is_punch': slabRow.is_punch ? 1 : 0 }, 'phase', 'update');
    };
    const handlePhaseTypeSelected = async (phase_type) => {
        setPunchPhaseType(phase_type);
    }
    const closePunchDialog = async () => {
        setPunchDialogOpen(false);
        setPunchPhaseType('Select');
    }
    const handleAddPunchPostSave = async (phaseStat) => {
        //const newPhase = await CentralCalls.upsertData({ 'lot_oid': selectedLotOid, 'phase_type': punchPhaseType, 'is_punch': 1 }, 'phase', 'insert');
        await CentralCalls.upsertData({ 'phase_oid': phaseStat.oid, 'status': 'complete', status_date: CentralCalls.sqlFormatdate(phaseStat.status_date), employee_oids: JSON.stringify(phaseStat.employee_oids), 'comments' : phaseStat.comments, 'hours' : phaseStat.hours, 'finished' :1 }, 'phase_status', 'insert');
        closePunchDialog();
    }
    const handleSelectedPlumberChange = (newValue) => {
        setSelectedPlumbers(newValue);
    }
    const addNewPhasePostSave = async (phaseRow) => {
        setAddPhaseDialogOpen(false);
        setRefreshPhaseKey(refreshPhaseKey + 1);
    }
    const procedureAddPOByPhaseDetailsInsert = useMemo(() => ({
        procedureName: 'ui_sp_wipAddPoByPhase',
        parameters: { 'phase_oid': selectedPhase, 'page_type': 'insert' },  // If there are any parameters, define them here as an object
    }), [selectedPhase]);
    const procedureAddPhaseDetails = useMemo(() => ({
        procedureName: 'ui_sp_wipInsertPunchPhase',
        parameters: { lot_oid: selectedLotOid },  // If there are any parameters, define them here as an object
    }), [selectedLotOid]);
    const procedureAddPunchDetails = useMemo(() => ({
        procedureName:'ui_sp_wipAddPunch',
        parameters: { lot_oid: selectedLotOid },
    }), [selectedLotOid])
    const slabProcedureDetails = useMemo(() => ({
        procedureName: 'ui_sp_getLotOverview',
        parameters: { 'lot_oid': selectedLotOid },  // If there are any parameters, define them here as an object
    }), [selectedLotOid]);
    const customFunctionsWipPhases = [
        {
            display_name: 'Add PO',
            type: 'money', // type must correspond to a FontAwesome icon name
            func: addPoClick,
            alwaysEnable: true
        }
    ]
    return (
        <>
            <h2>Phases
                <Tooltip title="Add New Punch">
                    <IconButton color="primary" aria-label="save" onClick={() => setPunchDialogOpen(true)} size="medium">
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            </h2>
            <JSONTableEditor
                key={refreshPhaseKey}
                customFunctions={customFunctionsWipPhases}
                procedureDetails={slabProcedureDetails}
                onSave={handleSavePhaseDetails}
                sortOrder={{
                    'is_cancelled': 'asc',
                    'status_date': 'asc',
                    'phase_order': 'asc'
                }} />

            <Dialog fullWidth={true} maxWidth='lg' open={addPhaseDialogOpen} onClose={() => setAddPhaseDialogOpen(false)}>
                <DialogTitle></DialogTitle>
                <DialogContent >
                    <h2>New Phase</h2>
                    <DynamicForm procedureDetails={procedureAddPhaseDetails}
                        onSave={addNewPhasePostSave}
                        saveName='Add'
                        crudType='insert' ></DynamicForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddPhaseDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth='lg' open={statusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogContent >
                    <>
                        <h2>New Po</h2>
                        <DynamicForm procedureDetails={procedureAddPOByPhaseDetailsInsert}
                            onSave={addNewPoToPhase}
                            saveName='Add'
                            crudType='insert' ></DynamicForm>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth='lg' open={PunchDialogOpen} onClose={() => closePunchDialog()}>
                <DialogContent>
                    <>
                        <h2> New Job</h2>

                        <DynamicForm procedureDetails={procedureAddPunchDetails}
                        onSave={handleAddPunchPostSave}
                        saveName='Add'
                        crudType='insert' ></DynamicForm>

                        
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closePunchDialog()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>


    );

}

export default WipInspectionSection;