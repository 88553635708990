import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Button, TextField, Grid, Typography, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import CentralCalls from '../centralCalls';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    tableHeader: {
        backgroundColor: '#0B426B',
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
}));

const GenericInsert = ({ onInsert,displayName, tableName, whereClause, defaults }) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({});
    const [fields, setFields] = useState([]);
    const [message, setMessage] = useState('');
    useEffect(() => {
        fetchColumns();
    }, [tableName]);

    const fetchColumns = async () => {
        fetch('/api/call-stored-procedureWithParam2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ procedureName: 'sp_getPageMetadata', param: tableName }),
        })
            .then(response => response.json())
            .then(data => {
                //** MIght need to bring this back** */
                // const initialFormData = data.columns.reduce((obj, field) => {
                //     if (whereClause && whereClause[field.name]) {
                //         obj[field.name] = whereClause[field.name];
                //     } else {
                //         obj[field.name] = '';
                //     }
                //     return obj;
                // }, {});
                const initialFormData = data.columns.reduce((obj, field) => {
                    // Check if there is a default value for this field
                    const defaultObject = (defaults || []).find(def => def.name === field.name);                    
                    // Assign values with precedence: whereClause -> defaults -> empty string
                    obj[field.name] = whereClause && whereClause[field.name]
                        ? whereClause[field.name]
                        : (defaultObject ? defaultObject.value : '');
                    
                    return obj;
                }, {});
                
                setFields(data.columns);
                setFormData(initialFormData);
            })
            .catch(error => console.error('Error:', error));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData({ ...formData, [name]: checked });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if(tableName === 'plan_number'){
            const copyObject = { ...formData };
            delete copyObject.oid;
            var planExist = await CentralCalls.fetchTableData('plan_number', copyObject);
            if(planExist && planExist.length > 0){
                setMessage('Plan Number Exists For This Builder');
                return;
            }
        }
        setMessage('');
        
        fetch('/api/upsert', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tableName: tableName, changeType: 'insert', jsonObj: formData }),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                setFormData(fields.reduce((obj, field) => ({ ...obj, [field.name]: '' }), {}));
                onInsert(result.result);
            })
            .catch((error) => console.error('Error adding builder:', error)); 
    };
    return (
        <form onSubmit={handleSubmit}>
            <Typography variant="h4" className={classes.header}>Add  {displayName || (tableName.charAt(0).toUpperCase() + tableName.slice(1))}</Typography>
            <Grid container spacing={2}>
                {fields.filter(field => field.name !== 'oid' && (!whereClause || !whereClause.hasOwnProperty(field.name))).map((field, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        {field.controlType === 'textbox' && (
                            <TextField
                                fullWidth
                                required={field.required === 1}
                                name={field.name}
                                label={field.display_name || field.name}
                                value={
                                    (defaults || []).find(def => def.name === field.name)
                                      ? (defaults || []).find(def => def.name === field.name).value
                                      : field.default
                                  }
                                  disabled={
                                    (defaults || []).find(def => def.name === field.name)
                                      ? (defaults || []).find(def => def.name === field.name).enabled === false
                                      : false
                                  }
                                type={field.type}
                                onChange={handleChange}
                            />
                        )}
                        {field.controlType === 'checkbox' && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={field.name}
                                        checked={formData[field.name]}
                                        onChange={handleCheckboxChange}
                                        color="primary"
                                    />
                                }
                                label={field.display_name || field.name}
                            />
                        )}
                        {field.controlType === 'list' && (
                            <FormControl fullWidth required={field.required === 1}>
                                <InputLabel id={`${field.name}-label`}>{field.display_name || field.name}</InputLabel>
                                <Select
                                    labelId={`${field.name}-label`}
                                    id={field.name}
                                    name={field.name}
                                    value={
                                        (defaults || []).find(def => def.name === field.name)
                                          ? (defaults || []).find(def => def.name === field.name).value
                                          : formData[field.name]
                                      }
                                      disabled={
                                        (defaults || []).find(def => def.name === field.name)
                                          ? (defaults || []).find(def => def.name === field.name).enabled === false
                                          : false
                                      }
                                    onChange={handleChange}
                                >
                                    {field.options.sort((a, b) => a.name.localeCompare(b.name)).map((option, index) => (
                                        <MenuItem value={option.id} key={index}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                       { field.default}
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button> <br/>
                    <label style={{color:'red'}}>{message}</label>
                </Grid>
            </Grid>
        </form>
    );
}
export default GenericInsert;
