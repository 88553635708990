import { React, useState, useEffect } from 'react';// adjust this import path to where your JSONTableEditor file is located
import { Dialog, Select, MenuItem, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import DynamicForm from '../../DynamicForm';
import JSONTableEditor from '../../JSONTableEditor';
import CentralCalls from '../../../centralCalls';
import { useTheme } from '@mui/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import DeleteConfirmationPopup from '../../DeleteConfirmationPopup';

const useStyles = makeStyles(theme => ({
    customHeader: {
        borderRadius: '10px',
        fontSize: 'large',
        padding: '0px',
        margin: '0px',
        border: 'black solid'
    }
}))
const OnHoldPhase= ({ phaseType, handleWipLinkClick, startDate, endDate, refreshMenu }) => {

    const [refreshKey, setRefreshKey] = useState(false);
    const handleRemoveOnHoldClose = async (phaseRow) => { setRefreshKey(!refreshKey);refreshMenu(); }

    const handleRemoveOnHold = async (phaseRow) => {
        await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'open' }, 'phase_status', 'update');
        setRefreshKey(!refreshKey);
        refreshMenu();
      }
    const customFunctionsOnHold = [
        {
          display_name: 'Hold',
          type: 'hold', // type must correspond to a FontAwesome icon name
          func: handleRemoveOnHold
        },
        {
          display_name: 'WIP',
          type: 'overview',
          func: handleWipLinkClick
        }
      ]
    const procedureDetailsOnHold = {
        procedureName: 'ui_sp_getPhasePageData',
        parameters: { phase_type: phaseType, page_type: 'hold', start_date : CentralCalls.sqlFormatdate(startDate), end_date: CentralCalls.sqlFormatdate(endDate) },  // If there are any parameters, define them here as an object
      };

return (
    <>
    <JSONTableEditor
        key={refreshKey}
        procedureDetails={procedureDetailsOnHold}
        onSave={handleRemoveOnHoldClose}
        sortOrder={{
          'date_scheduled': 'desc'
        }}
        hideEdit={true}
        customFunctions={customFunctionsOnHold} />
    </>
);
}
export default OnHoldPhase;
