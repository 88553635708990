import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CentralCalls from '../centralCalls';
import {Select, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(3),
    },
    selectContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    select: {
        marginBottom: theme.spacing(2),
        minWidth: 200,
    },
    saveButton: {
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    title: {
        marginBottom: theme.spacing(2),
    },
}));

const AdminEmployeePhase = () => {
    const classes = useStyles();
    const [phaseList, setPhaseList] = useState([]);
    const [selectedPhases, setSelectedPhases] = useState([]);
    const [plumberList, setPlumberList] = useState([]);
    const [selectedPlumber, setSelectedPlumber] = useState('');

    useEffect(() => {
        CentralCalls.fetchTableDataNoWhere('employee').then(response => {
            const sortedResponse = response.slice().sort((a, b) => a.name.localeCompare(b.name));
            setPlumberList(sortedResponse);
        });
    }, []);

    useEffect(() => {
        CentralCalls.fetchTableDataNoWhere('phase_type').then(response => {
            const sortedResponse = response.slice().sort((a, b) => a.name.localeCompare(b.name));
            setPhaseList(sortedResponse);
        });
    }, []);
    const plumberChange = (plumber) => {
        setSelectedPlumber(plumber);
        console.log(plumber);
        CentralCalls.fetchTableData('employee_phase', {employee_oid:plumber }).then(response => {
            console.log('repsonse');
            console.log(response);
            //const sortedResponse = response.slice().sort((a, b) => a.name.localeCompare(b.name));
            console.log('phases below...')
            //console.log(sortedResponse);
            setSelectedPhases(response.map(phase => phase.phase_type_oid));
        });
    };
    const handleSave = async () => {
        // Add save functionality here
        await CentralCalls.callStoredProcedureWithParams('deleteEmployePhases', {employee_oid: selectedPlumber})
        await selectedPhases.forEach(phase => {
            CentralCalls.upsertData({employee_oid: selectedPlumber, phase_type_oid: phase},'employee_phase', 'insert')
        });
        setSelectedPhases([]);
        setSelectedPlumber('');
    };

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.title}>Employee Phases</Typography>
            <div className={classes.selectContainer}>
                <Select
                    className={classes.select}
                    name="selectedPlumber"
                    value={selectedPlumber}
                    onChange={(e) => plumberChange(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="" disabled>Select Employee</MenuItem>
                    {plumberList.map(plumber => (
                        <MenuItem key={plumber.oid} value={plumber.oid}>
                            {plumber.name}
                        </MenuItem>
                    ))}
                </Select>
                <Select
                    className={classes.select}
                    name="selectedPhases"
                    multiple
                    value={selectedPhases}
                    onChange={(e) => setSelectedPhases(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="" disabled>Select Phases</MenuItem>
                    {phaseList.map(phase => (
                        <MenuItem key={phase.oid} value={phase.oid}>
                            {phase.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <Button
                variant="contained"
                className={classes.saveButton}
                onClick={handleSave}
                disabled={!selectedPlumber || selectedPhases.length === ''}
            >
                Save
            </Button>
        </div>
    );
};

export default AdminEmployeePhase;
