
import React, { useState, useMemo } from 'react';
import {  IconButton, Dialog,DialogTitle,DialogContent, Button, DialogActions  } from '@mui/material';
import { faPlus, faRefresh, faPrint, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
// Define the component and destructure the prop from the component's props
const WipLotSection = ({ selectedLotOid }) => {
    const [deleteLotDialogOpen,setDeleteLotDialogOpen] = useState(false);
    const onLotUpdate = (obj) => { }    
    const handleDeleteLot = async () => {
        console.log('deleting LOT');
        await CentralCalls.callStoredProcedureWithParams('sp_deleteLot', {lot_oid : selectedLotOid});
        const path = '/';
        window.open(path, "_blank");
      }
    // You can use the selectedLotOid prop in your component logic
    // For example, displaying it or using it in a function
    const procedureDetails = useMemo(() => ({
        procedureName: 'ui_sp_getLotPageInsert',
        parameters: { 'lot_oid': selectedLotOid, 'page_type': 'wip' },  // If there are any parameters, define them here as an object
      }), [selectedLotOid]);
    return (
        <div>
            <h2>Lot <IconButton color="primary" aria-label="save" onClick={() => setDeleteLotDialogOpen(true)} size="medium">
                <FontAwesomeIcon icon={faTrashCan} />
            </IconButton></h2>
            <DynamicForm procedureDetails={procedureDetails} onSave={onLotUpdate} saveName={'Update'} crudType={'update'} ></DynamicForm>

            <Dialog fullWidth={true} maxWidth='lg' open={deleteLotDialogOpen} onClose={() => setDeleteLotDialogOpen(false)}>
        <DialogTitle></DialogTitle>
        <DialogContent >
          <h2>Confirm Delete Lot</h2>     
          <Button variant="contained" color="secondary" onClick={() => handleDeleteLot()}>Delete</Button>     
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteLotDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    );
}

export default WipLotSection;
