import React, { useState, useEffect } from 'react';
import CentralCalls from '../../centralCalls';
import { Button, TextField} from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    exportContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    datePickerContainer: {
        display: 'flex',
        gap: theme.spacing(2.5),
        alignItems: 'center',
    },
}));

function Export() {
    const classes = useStyles();
    
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);

    useEffect(() => {
        setIsDownloadEnabled(startDate !== '' && endDate !== '');
    }, [startDate, endDate]);

    const handleDownload = async () => {
        if (isDownloadEnabled) {
            await CentralCalls.exportDataToExcel(startDate, endDate);
        }
    };

    return (
        <div className={classes.exportContainer}>
            <h1>Download System Data</h1>
            
            <div className={classes.datePickerContainer}>
                <TextField
                    id="start-date"
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                
                <TextField
                    id="end-date"
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>

            <Button 
                variant="contained" 
                color="primary"
                onClick={handleDownload} 
                disabled={!isDownloadEnabled}>
                Download Data
            </Button>
        </div>
    );
}

export default Export;
