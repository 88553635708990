import React, { useState, useEffect, useMemo } from 'react';
import {  Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CentralCalls from '../../centralCalls';
import { faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const useStyles = makeStyles((theme) => ({
    fullScreen: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: 'white',
        overflow: 'auto',
        margin: 0,
        padding: 0
    },
    tableHeaderCell: {
        backgroundColor: '#f4f4f4', // Set your desired background color
        fontWeight: 'bold',
        borderBottom: '1px solid #ddd', // Add a bottom border for separation
        padding: theme.spacing(1),
        borderRight: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'wrap',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    table: {
        width: 'calc(100% - 40px)', // Deducting the overall table margins 
        maxWidth: '100%',
        tableLayout: 'fixed',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableCell: {
        borderRight: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'wrap',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    row: {
        borderTop: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
    },
    lastCell: {
        borderRight: 'none',
    }
}));


function PrintPage() {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [isPieceWork, setIsPeiceWork] = useState(false);
    const [plumbers, setPlumbers] = useState('');
    const handlePrint = () => {
        window.print();
    };

    const location = useLocation();

    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const employee_oids_string = query.get('employee_oids');

    const startDate = query.get('start_date');
    const endDate = query.get('end_date');
    const qry = query.get('qry');

    useEffect(() => {
        const employee_oids_array = employee_oids_string ? employee_oids_string.split(',').map(Number) : [];
        if (qry === 'ui_sp_schedulePieceWork') {
            setIsPeiceWork(true);
        }
        const fetchData = async () => {
            try {
                const response = await CentralCalls.callStoredProcedureWithParams(qry, { employee_oids: employee_oids_array, start_date: startDate, end_date: endDate });
                setData(response.data);
                // Get distinct values from the 'plumbers' column
                const distinctPlumbers = [...new Set(response.data.map(item => item.employee_names))];
                // Create a comma-separated string
                setPlumbers(distinctPlumbers.join(', '));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, [employee_oids_string, endDate, startDate, qry]);

    return (
        <div className={classes.fullScreen}>

            <IconButton style={{ float: 'right' }} color="primary" aria-label="save" onClick={() => { handlePrint() }} size="medium">
                <FontAwesomeIcon icon={faPrint} />
            </IconButton>
            <div style={{ marginLeft: '25px', marginTop: '25px' }}>
                <span style={{ fontWeight: 'bold' }}>Plumbers:</span> {plumbers}
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.row} >
                        <TableCell className={classes.tableHeaderCell}>Builder</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }} className={classes.tableHeaderCell}>Neighborhood</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }} className={classes.tableHeaderCell}>Lot Number</TableCell>
                        {!isPieceWork && (
                            <TableCell style={{ fontWeight: 'bold' }} className={classes.tableHeaderCell}>Sewer</TableCell>
                        )}
                        <TableCell style={{ fontWeight: 'bold' }} className={classes.tableHeaderCell}>Phase</TableCell>
                        {isPieceWork && (
                            <TableCell style={{ fontWeight: 'bold' }} className={classes.tableHeaderCell}>Date Complete</TableCell>
                        )}
                        <TableCell style={{ fontWeight: 'bold' }} className={classes.tableHeaderCell}>Hours</TableCell>
                        {isPieceWork && (
                            <TableCell style={{ fontWeight: 'bold' }} className={classes.tableHeaderCell}>Cost</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row) => (
                        <>
                            <TableRow key={row.lot_num}>
                                <TableCell className={classes.tableCell}>{row.builder}</TableCell>
                                <TableCell className={classes.tableCell}>{row.neighborhood}</TableCell>
                                <TableCell className={classes.tableCell}>{row.lot_num}</TableCell>
                                {!isPieceWork && (
                                    <TableCell className={classes.tableCell}>{row.sewer_location}</TableCell>
                                )}
                                <TableCell className={classes.tableCell}>{row.phase_type}</TableCell>
                                {isPieceWork && (
                                    <TableCell className={classes.tableCell}>
                                        {(() => {
                                            const date = new Date(row.date_complete);
                                            const day = date.getDate().toString().padStart(2, '0');
                                            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
                                            const year = date.getFullYear();
                                            return `${month}-${day}-${year}`;
                                        })()}
                                    </TableCell>
                                )}
                                <TableCell className={classes.tableCell}>{row.hours}</TableCell>
                                {isPieceWork && (
                                    <TableCell className={classes.tableCell}>{row.cost}</TableCell>
                                )}

                            </TableRow>
                            {!isPieceWork && (
                                <TableRow>
                                    <TableCell colSpan={6} className={classes.tableCell}><span style={{ float: 'left', fontSize: 'small', fontWeight: 'bolder' }}>Comments:</span>
                                        {row.punch_comments !== null && row.punch_comments !== '' && row.punch_comments !== undefined ? 'Punch:' + row.punch_comments + '. Comments' + row.comments : row.comments}
                                    </TableCell>
                                </TableRow>
                            )}
                            {!isPieceWork && (
                                <TableRow>
                                    <TableCell colSpan={6} className={classes.tableCell}><span style={{ float: 'left', fontSize: 'small', fontWeight: 'bolder' }}>Result:</span></TableCell>
                                </TableRow>
                            )}
                            {isPieceWork && (
                                <TableRow>
                                    <TableCell colSpan={7} className={classes.tableCell}><span style={{ float: 'left', fontSize: 'small', fontWeight: 'bolder' }}>Result:</span>{row.comments}</TableCell>
                                </TableRow>
                            )}
                        </>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
export default PrintPage;
