import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LotFilter from '../workInProgress/LotFilter';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CentralCalls from '../../centralCalls';
import ButtonMenu from '../ButtonMenu';
import ActivePhase from '../phase/parts/activePhase';
import ActivePo from './ActivePo';
import PaidPo from './PaidPo';
import PoItems from './PoItems';
import NeedPo from './NeedPo';
import CompletePo from './CompletePo';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    select: {
        minWidth: 200,
    },
    accordion: {
        borderRadius: '15px',
        margin: '10px 0',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}));
const BasePo = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const menuItems = [{order:1, name_id: 'needpo', display_name :'Need POs'},{order:2, name_id: 'active', display_name :'Billable'}, {order:3, name_id: 'paid', display_name:'Payments'}, {order:4, name_id: 'complete', display_name:'Complete'}]
    const [AddPODialogOpen, setAddPODialogOpen] = useState(false);
    const [SelectedPhaseOid, setSelectedPhaseOid] = useState(null);
    const [selectedMenuItemNameId, setSelectedMenuItemNameId] = useState('needpo');
    const [SelectedInsertLotOid, setSelectedInsertLotOid] = useState(null);
    const [SelectedLotOid, setSelectedLotOid] = useState(null);
   
    const addPOClick = async () => {
        await CentralCalls.upsertData({ 'phase_oid': SelectedPhaseOid }, 'po', 'insert');
        setSelectedLotOid(null);
        setSelectedPhaseOid(null);
        setAddPODialogOpen(false);
    }
    const openCustomPO = async () => {
        setAddPODialogOpen(true);
    }
    const handlePostInsertPo = async (poRow) => {
        setAddPODialogOpen(false);
    }
    const menuItemSelectected = (menuItem) => {
        setSelectedMenuItemNameId(menuItem.name_id);
    }
    const handleLotSelected = async (lot_oid) => {
        setSelectedInsertLotOid(lot_oid);
    }

    const procedureDetailsInsertPo = {
        procedureName: 'ui_sp_insertPoForm',
        parameters: { 'lot_oid': SelectedInsertLotOid }
    };
    return (
        <>
        <h1>PO's
                <Tooltip title="Add PO">
                    <IconButton color="primary" aria-label="save" onClick={openCustomPO} size="medium">
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            </h1>
            <ButtonMenu onMenuSelect={menuItemSelectected} menuItems={menuItems} defaultMenuItem={menuItems[0]} ></ButtonMenu>
            {selectedMenuItemNameId === 'needpo' && (
                     <NeedPo ></NeedPo>
                )}
            {selectedMenuItemNameId === 'active' && (
                     <ActivePo ></ActivePo>
                )}
             {selectedMenuItemNameId === 'paid' && (
                <>
                     <PaidPo  ></PaidPo>
                     </>
                )}
            {selectedMenuItemNameId === 'complete' && (
                <>
                     <CompletePo lot_oid={SelectedInsertLotOid}  ></CompletePo>
                     </>
                )}
            <Dialog fullWidth={true} maxWidth='lg' open={AddPODialogOpen} onClose={() => setAddPODialogOpen(false)}>
                <DialogTitle><h1>New PO</h1></DialogTitle>
                <DialogContent >
                    <LotFilter onLotChange={handleLotSelected} /><br />
                    <DynamicForm
                        procedureDetails={procedureDetailsInsertPo}
                        onSave={handlePostInsertPo}
                        saveName='Add PO'
                        crudType='insert' />                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddPODialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default BasePo;