import React, { useState, useRef } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './WipTabs.css'; // Import the CSS file for component styling
import WipLotSection from './WipLotSection';
import WipPOSection from './WipPOSection';
import WipPermitSection from './WipPermitSection';
import WipUndergroundTicketsSection from './WipUndergroundTicketsSection';
import WipInspectionSection from './WipInspectionsSection';
import WipPhasesSection from './WipPhasesSection';
import WipMaterialSection from './WipMaterialSection';
import WipNotes from './WipNotes';
const WipTabs = ({ selectedLotOid }) => {
  return (
    <Tabs>
      <TabList>
        <Tab>Lot Overview</Tab>
        <Tab>PO's</Tab>
        <Tab>Permits</Tab>
        <Tab>Underground Tickets</Tab>
        <Tab>Inspections</Tab>
        <Tab>Phases</Tab>
        <Tab>Materials</Tab>
        <Tab>Notes</Tab>
      </TabList>

      <TabPanel>
         <WipLotSection selectedLotOid={selectedLotOid} />
      </TabPanel>
      <TabPanel>
         <WipPOSection selectedLotOid={selectedLotOid} />
      </TabPanel>
      <TabPanel>
       <WipPermitSection selectedLotOid={selectedLotOid} />
      </TabPanel>
      <TabPanel>
       <WipUndergroundTicketsSection selectedLotOid={selectedLotOid} />
      </TabPanel>
      <TabPanel>
       <WipInspectionSection selectedLotOid={selectedLotOid} />
      </TabPanel>
      <TabPanel>
       <WipPhasesSection selectedLotOid={selectedLotOid} />
      </TabPanel>
      <TabPanel>
       <WipMaterialSection selectedLotOid={selectedLotOid} />
      </TabPanel>
      <TabPanel>
       <WipNotes selectedLotOid={selectedLotOid} />
      </TabPanel>
    </Tabs>
  );
};

export default WipTabs;
