import React , {useState} from 'react';
import AllPhase from './allPhase'; 
import BasePhase from './parts/basePhase';
import FilterButtonGroup from './filterButtonGroup';
const PhasePage = () => {
    const [phaseType, setPhaseType] = useState('slab')
    const handlePhaseTypeSelect = (phase) => {
        setPhaseType(phase.name_id);
    };

    return (
        <div>
            <h1>Phases</h1>                  
            <FilterButtonGroup onPhaseSelect={handlePhaseTypeSelect} />
            <BasePhase phaseType={phaseType} />
        </div>
    );
};

export default PhasePage;
