import React, { useState, useEffect } from 'react';
import { Dialog, Typography, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, Button, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TableContainer, Paper, Link } from '@mui/material';
import CentralCalls from '../../centralCalls';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import JSONTableEditor from '../JSONTableEditor';
import PoItems from './PoItems';
import { intervalToDuration } from 'date-fns';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gap: theme.spacing(1),
        alignItems: 'center',
        margin: 20,
    },
    selectList: {
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
    },
    textBox: {
        width: '100%',
        borderRadius: theme.shape.borderRadius,
    },
    dateField: {
        width: '100%',
    },
    updateButton: {
        width: '20%',
        padding: theme.spacing(1),
        float:'right'  
    },
    tableContainer: {
        maxHeight: 500,
    },
    tableHead: {
        backgroundColor: theme.palette.background.default,
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    completedRow: {
        backgroundColor: 'red',
    },
}));

const PaidPo = () => {
    const classes = useStyles();
    const [builders, setBuilders] = useState([]);
    const [selectedBuilder, setSelectedBuilder] = useState('');
    //const [poList, setPoList] = useState([]);
    const [costTotal, setCostTotal] = useState(0);
    //const [selectedPo, setSelectedPo] = useState({});
    const [checkNumber, setCheckNumber] = useState('');
    const [checkAmount, setCheckAmount] = useState(0);
    const [datePaid, setDatePaid] = useState('');
    const [checkedPos, setCheckedPos] = useState({});
    const [selectedPo, setSelectedPo] = useState({});
    const [openPoItems, setOpenPoItems] = useState(false);
    const [refreshKey, setRefreshKey] = useState(false);
    //const [openPoItems, setOpenPoItems] = useState(false);
    //const [allChecked, setAllChecked] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        loadBuilders();
    }, []);
    const handleOpenPoItems = async (poRow) => {
        setOpenPoItems(true);
        setSelectedPo(poRow);
    }
    const procedureDetails = {
        procedureName: 'sp_massUpdatePoOptions2',
        parameters: { 'builder_oid': selectedBuilder },  // If there are any parameters, define them here as an object
    };
    const formatCost = (cost) => {
        return `$${cost.toFixed(2)}`;
    };
    const loadBuilders = async () => {
        const rows = await CentralCalls.fetchTableDataNoWhere('builder');
        setBuilders(rows);
    };

    const handleBuilderChange = async (event) => {
        setSelectedBuilder(event.target.value);
        // const pos = await CentralCalls.callStoredProcedureWithParams('sp_massUpdatePoOptions', { builder_oid: parseInt(event.target.value) });
        // pos.sort((a, b) => {
        //     if (a.neighborhood !== b.neighborhood) {
        //         return a.neighborhood.localeCompare(b.neighborhood);
        //     } else if (a.lot_num !== b.lot_num) {
        //         return a.lot_num.localeCompare(b.lot_num);
        //     } else {
        //         return a.phase.localeCompare(b.phase);
        //     }
        // });
        // setPoList(pos);
        setCheckedPos({});
        //setAllChecked(false);
    };

    const handleCheckNumberChange = (event) => {
        setCheckNumber(event.target.value);
    };
    const handleCheckAmountChange = (event) => {
        setCheckAmount(event.target.value);
        console.log(event.target.value)
        console.log(costTotal);
        console.log(event.target.value === costTotal);
    };
    const handleDatePaidChange = (event) => {
        setDatePaid(event.target.value);
    };
    const handleSetCheckedPos = (poList) => {
        setCheckedPos(poList);
        setCostTotal(poList.reduce((sum, row) => sum + (row.price || 0), 0))
    }
    const handleUpdate = async () => {
        // console.log(checkedPos);
        // const checkedPosArray = checkedPos.filter(item => item.oid).map(item => item.oid);
        // // Object.keys(checkedPos).filter(key => checkedPos[key]).map(Number);
        // console.log(poList);
        console.log('handing update');
        console.log({check_number:checkNumber, date_paid:CentralCalls.sqlFormatdate(datePaid), amount:checkAmount});
        const checkRow = await CentralCalls.upsertData({check_number:checkNumber, date_paid:CentralCalls.sqlFormatdate(datePaid), amount:checkAmount}, 'po_check', 'insert');
        console.log('checkrow...')
        console.log(checkRow);
        checkedPos.forEach(po => {
            //if (checkedPosArray.includes(po.oid)) {
            console.log('running');
            CentralCalls.upsertData(
                {
                    oid: po.oid,
                    check_number: checkNumber,
                    check_oid:checkRow.oid,
                    date_paid: CentralCalls.sqlFormatdate(datePaid)
                },
                'po',
                'update'
            );
            //}
        });
        setRefreshKey(!refreshKey);
        setCheckAmount(0);
        setCheckNumber('');
        setDatePaid(null);
        setCostTotal(0);
        // Filter out the updated items from poList
        //setPoList(poList.filter(po => !checkedPosArray.includes(po.oid)));
    };
    // const handleOpenPoItems = async (poRow) => {
    //     setOpenPoItems(true);
    //     setSelectedPo(poRow);
    // }
    const handleWipLinkClick = async (phaseRow) => {
        console.log(phaseRow);
        const path = '/wip?lot_oid=' + phaseRow.lot_oid;
        navigate(path);
    }
    const handleSavePo = async (poRow) => {

    };
    //     const newCheckedPos = {};
    //     poList.forEach(po => {
    //         newCheckedPos[po.oid] = !allChecked;
    //     });
    //     setCheckedPos(newCheckedPos);
    //     setAllChecked(!allChecked);
    // };
    const customFunctions = [
        // {
        //     display_name: 'Line Items',
        //     type: 'list', // type must correspond to a FontAwesome icon name
        //     func: handleOpenPoItems
        // },
        {
            display_name: 'WIP',
            type: 'overview',
            func: handleWipLinkClick
        }
    ]
    const compareTwoDecimalPlaces = (num1, num2) => {
        const roundedNum1 = Math.round(num1 * 100) / 100;
        const roundedNum2 = Math.round(num2 * 100) / 100;
        return roundedNum1 === roundedNum2;
    };
    const isUpdateButtonEnabled = selectedBuilder && checkNumber && datePaid && costTotal > 0 && compareTwoDecimalPlaces(checkAmount, costTotal);
    return (
        <>
            <div className={classes.container}>
                <Select
                    className={classes.selectList}
                    value={selectedBuilder}
                    onChange={handleBuilderChange}
                    displayEmpty
                >
                    <MenuItem value="">
                        <em>Select Builder</em>
                    </MenuItem>
                    {builders && builders.sort((a, b) => a.name.localeCompare(b.name)).map((builder) => (
                        <MenuItem key={builder.oid} value={builder.oid}>
                            {builder.name}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    className={classes.textBox}
                    label="Check Number"
                    variant="outlined"
                    value={checkNumber}
                    onChange={handleCheckNumberChange}
                    InputProps={{ style: { borderRadius: '50px' } }}
                />
                <TextField
                    className={classes.dateField}
                    label="Date Paid"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={datePaid}
                    onChange={handleDatePaidChange}
                />
                <TextField
                    className={classes.textBox}
                    label="Check Amount"
                    type="number"
                    value={checkAmount}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    onChange={handleCheckAmountChange}
                />
                <TextField
                    className={classes.textBox}
                    label="Select Items Cost"
                    value={formatCost(costTotal)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </div>
            
            <Button
                className={classes.updateButton}
                variant="contained"
                color="primary"
                disabled={!isUpdateButtonEnabled}
                onClick={handleUpdate}
            >
                Update
            </Button>
                <br/><br/>
            {/* <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell>
                                <Link component="button" variant="body2" onClick={handleToggleAll}>
                                    {allChecked ? "Uncheck All" : "Check All"}
                                </Link>
                            </TableCell>
                            <TableCell>Neighborhood</TableCell>
                            <TableCell>Lot Number</TableCell>
                            <TableCell>Phase</TableCell>
                            <TableCell>PO Number</TableCell>
                            <TableCell>Billed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {poList.map((po) => (
                            <TableRow key={po.oid} className={po.completed === true ? classes.completedRow : ''}>
                                <TableCell>
                                    <Checkbox
                                        checked={!!checkedPos[po.oid]}
                                        onChange={() => handleCheckboxChange(po.oid)}
                                    />
                                </TableCell>
                                <TableCell>{po.neighborhood}</TableCell>
                                <TableCell>{po.lot_num}</TableCell>
                                <TableCell>{po.phase}</TableCell>
                                <TableCell>{po.po_number}</TableCell>
                                <TableCell>{po.date_billed}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}
            <JSONTableEditor
                key={refreshKey}
                procedureDetails={procedureDetails}
                onSave={handleSavePo}
                allowDelete={false}
                limitHeight={false}
                showCheckedRows={true}
                handleSetCheckedRows={handleSetCheckedPos}
                sortOrder={{
                    'builder': 'desc',
                    'neighborhood': 'desc',
                    'lot': 'desc'
                }} customFunctions={customFunctions}
            />

            <Dialog fullWidth={true} maxWidth='lg' open={openPoItems} onClose={() => setOpenPoItems(false)}>
                <DialogTitle><h1>PO Line Items</h1></DialogTitle>
                <DialogContent >
                    <PoItems poOid={selectedPo.oid} ></PoItems>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPoItems(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PaidPo;