import { React, useState, useEffect } from 'react';// adjust this import path to where your JSONTableEditor file is located
import { Dialog, Select, MenuItem, DialogActions,  DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import DynamicForm from '../../DynamicForm';
import JSONTableEditor from '../../JSONTableEditor';
import CentralCalls from '../../../centralCalls';
import { useTheme } from '@mui/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import DeleteConfirmationPopup from '../../DeleteConfirmationPopup';
import ActivePhase from '../parts/activePhase';
import CompletePhase from '../parts/completedPhase';
import DeletedPhase from '../parts/deletedPhase';
import OnHoldPhase from '../parts/onHoldPhase';
import LotFilter from '../../workInProgress/LotFilter';
import ActivePhaseButtonGroup from '../activePhaseButtonGroup';
const useStyles = makeStyles(theme => ({
  customHeader: {
    borderRadius: '10px',
    fontSize: 'large',
    padding: '0px',
    margin: '0px',
    border: 'black solid'
  }
}))
const BasePhase = ({ phaseType }) => {
  const classes = useStyles();
  const [StatusDialogOpen, setStatusDialogOpen] = useState(false);
  const [UndergroundDialogOpen, setUndergroundDialogOpen] = useState(false);
  const [addQCDialogOpen, setAddQCDialogOpen] = useState(false);
  const [PunchDialogOpen, setPunchDialogOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshMenuKey, setRefreshMenuKey] = useState(false);
  const [LotOid, setLotOid] = useState(null);
  const [filter,setFilter] = useState({name_id : 'open', name : 'Open'});
  const [filterStartDate, setFilterStartDate] = useState(new Date());
  const [filterEndDate, setFilterEndDate] = useState(new Date());
  const [PhaseOid, setPhaseOid] = useState(null);
  const [lotTitle, setLotTitle] = useState('not title');
  const [punchLotOid, setPunchLotOid] = useState(null);
  const [refreshUndergroundKey, setRefreshUndergroundKey] = useState(0);
  const [AddQCValid, setAddQCValid] = useState(null);
  const [NewQCLotOid, setNewQCLotOid] = useState(0);
  const [selectedPhaseForQC, setSelectedPhaseForQC] = useState(0);
  const [selectedSecondaryPhaseForQC, setSelectedSecondaryPhaseForQC] = useState('slab_inspection');
  const [qcPhaseOptions, setQcPhaseOptions] = useState([]);
  const [secondaryInspectionLotOid,setSecondaryInspectionLotOid] = useState(0);
  const [phaseName, setPhaseName] = useState('');
  const [isQc, setIsQc] = useState(0);
  const [isInspection, setIsInspection] = useState(0);
  const [phaseMetaData, setPhaseMetaData] = useState(null);
  const [qcTitle, setQcTitle] = useState('UNSET');
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchData = async () => {
      const phase_nm = await CentralCalls.fetchTableData('phase_type', { name_id: phaseType });
      setPhaseMetaData(phase_nm[0]);
      setPhaseName(phase_nm[0].name);
      setIsQc(phase_nm[0].is_qc);
      setIsInspection(phase_nm[0].is_inspection);
      if (phase_nm[0].is_inspection === 1) {
        setQcTitle("Add Inspection");
      } else {
        setQcTitle("Add Quality Check");
      }
    };

    fetchData();
  }, [phaseType], [isInspection]);
  useEffect(() => {
    const getLotTitle = async () => {
      const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', { lot_oid: LotOid });
      setLotTitle(title.title);
    }
    getLotTitle();
  }, [LotOid]);
useEffect(() => {
    const fetchData = async () => {
        const phase_nm = await CentralCalls.fetchTableData('phase_type', { name_id: phaseType });
        setPhaseName(phase_nm[0].name);
        setIsQc(phase_nm[0].is_qc);
        setIsInspection(phase_nm[0].is_inspection);
        if (phase_nm[0].is_inspection === 1) {
            setQcTitle("Add Inspection");
        } else {
            setQcTitle("Add Quality Check");
        }
    };

    fetchData();
}, [phaseType], [isInspection]);
  const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Confirmation Modal"  >
        <h2>Confirm</h2>
        <p>Are you sure you want to proceed?</p>
        <button onClick={onConfirm}>Yes</button>
        <button onClick={onClose}>No</button>
      </Modal>
    );
  };
  const closePunchDialog = async () => {
    setPunchDialogOpen(false);
    setPunchLotOid(null);
  }
  const handleLotSelected = async (lot_oid) => {
    setPunchLotOid(lot_oid);
  }

   const handleWipLinkClick = async (phaseRow) => {
     const path = '/wip?lot_oid=' + phaseRow.lot_oid;
     navigate(path);
   }
   
   const refreshMenu = async () => {
    console.log('freshing menu key base')
    setRefreshMenuKey(!refreshMenuKey);
   }
  // const handleCompleteUndergroundClose = async (undergroundRow) => {
  //   setUndergroundDialogOpen(false);
  // }
  // const handleSavePhaseStatus = async (phaseRow) => {
  //    setStatusDialogOpen(!StatusDialogOpen);
  //  }

  const onFilterSelect  = async (filter) => {
        setFilter(filter.filter);
        setFilterStartDate(filter.startDate);
        setFilterEndDate(filter.endDate);
        console.log('refreshing menu');
        setRefreshMenuKey(refreshMenuKey + 1)
  }

  const addPunch = async () => {
    setPunchDialogOpen(true);
  }
  const OpenQCDialogOpen = async () => {
    setAddQCDialogOpen(true);
    setQcPhaseOptions([]);
    setSelectedPhaseForQC(0);
    setAddQCValid(false);
  }
  const handleAddPunchClick = async () => {
    const newPhase = await CentralCalls.upsertData({ 'lot_oid': punchLotOid, 'phase_type': phaseType, 'is_punch': 1 }, 'phase', 'insert');
    await CentralCalls.upsertData({ 'phase_oid': newPhase.oid }, 'phase_status', 'insert');
    closePunchDialog();
  }
  const setQCPhaseOptions  = async (selected_lot_oid, secondary_phase_type) => {
    const qcForPhaseType = phaseType.split('_')[0];
    var this_phase_type = qcForPhaseType;
    if (isInspection === 1) {
      this_phase_type = secondary_phase_type.split('_')[0];
    }
    //console.log({ lot_oid: parseInt(lot), phase_type: this_phase_type, is_inspection : isInspection, inspection_phase_type : selectedSecondaryPhaseForQC })
    const result = await CentralCalls.callStoredProcedureWithParams('sp_checkLotExistingQC', { lot_oid: parseInt(selected_lot_oid), phase_type: this_phase_type, is_inspection: isInspection, inspection_phase_type: secondary_phase_type });
    if (!result || result.length === 0) {
      setQcPhaseOptions([], selectedSecondaryPhaseForQC);
      //CentralCalls.upsertData({ deleted: 0, oid: deletedRow.oid }, 'permit', 'update');
      //setAddQCValid(false);         
    } else {
      setNewQCLotOid(selected_lot_oid);
      //setAddQCValid(true);
      setQcPhaseOptions(result,selectedSecondaryPhaseForQC);
    }
  }
  const confirmLotQCAddOption = async (lot) => {
    setQCPhaseOptions(lot, selectedSecondaryPhaseForQC);
    setSecondaryInspectionLotOid(lot);
  }
  const insertNewQC = async () => {
    var phase_type_now = phaseType;
    if(isInspection){
      phase_type_now = selectedSecondaryPhaseForQC;
    }
    const phaseRow = await CentralCalls.upsertData({ lot_oid: NewQCLotOid, phase_type: phase_type_now, qc_phase_oid: selectedPhaseForQC }, 'phase', 'insert');
    await CentralCalls.upsertData({ 'phase_oid': phaseRow.oid }, 'phase_status', 'insert');
    setAddQCDialogOpen(false);
  }
  const handleSelectedPhaseForQCChange = async (event) => {
    const oid = event.target.value;
    if (oid !== 0) {
      setAddQCValid(true);
    }
    else {

    }
    setSelectedPhaseForQC(oid);
  }
  const handleSelectedSecondaryPhaseForQCChange = async (event) => {
    const phase_type = event.target.value;
    if (isInspection && selectedSecondaryPhaseForQC === 0) {
      setAddQCValid(true);
    }
    await setSelectedSecondaryPhaseForQC(phase_type);
    setQCPhaseOptions(secondaryInspectionLotOid, phase_type);
  }

  // const customFunctionsEmpty = [

  // ]

  // const procedureDetailsUndergroundHistory = {
  //   procedureName: 'ui_sp_wipUndergroundTickets',
  //   parameters: { lot_oid: LotOid },
  // }
  // const handleSaveUndergroundHistory = async (phaseRow) => { }

  // const procedureDetailsUndergroundTickets = {
  //   procedureName: 'ui_getUndergroundTickets',
  //   parameters: { phase_oid: PhaseOid, lot_oid: LotOid }
  // }

  return (
    <>
      <h2> {phaseName === 'Slab Inspection' ? 'Inspections' : phaseName}
        {isQc !== 1 && (
          <Tooltip title="Add Punch">
            <IconButton color="primary" aria-label="save" onClick={addPunch} size="medium">
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
        )}
        {isQc === 1 && (
          <Tooltip title={qcTitle}>
            <IconButton color="primary" aria-label="save" onClick={() => { OpenQCDialogOpen() }} size="medium">
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
        )}
      </h2>
       
      <ActivePhaseButtonGroup refreshMenuKey={refreshMenuKey} phaseType={phaseType} theme={phaseMetaData} onFilterSelect={onFilterSelect} ></ActivePhaseButtonGroup>
       <h3>{filter.name}</h3>
       {(['scheduled', 'open', 'upcoming'].includes(filter.name_id) && (
        <ActivePhase refreshMenu={refreshMenu} isQc={isQc} isInspection={isInspection} filterType={filter.name_id } phaseType={phaseType} handleWipLinkClick={handleWipLinkClick} />
       ))}

      {filter.name_id === 'hold' && (
        <OnHoldPhase refreshMenu={refreshMenu} endDate={filterEndDate} startDate={filterStartDate} phaseType={phaseType} handleWipLinkClick={handleWipLinkClick} />
      )}

      {filter.name_id === 'complete' && (
        <CompletePhase key={refreshMenuKey} refreshMenu={refreshMenu} endDate={filterEndDate} startDate={filterStartDate} phaseType={phaseType} handleWipLinkClick={handleWipLinkClick} isQc={isQc} />
      )}

      {filter.name_id === 'deleted' && (
        <DeletedPhase refreshMenu={refreshMenu} endDate={filterEndDate} startDate={filterStartDate} phaseType={phaseType} handleWipLinkClick={handleWipLinkClick} />
      )}


            <Dialog fullWidth={true} maxWidth='lg' open={PunchDialogOpen} onClose={() => closePunchDialog()}>
                <DialogContent >
                    <>
                        <h2>{phaseName} Punch</h2>
                        <LotFilter onLotChange={handleLotSelected} /><br />
                        <Button variant="contained" type="submit" color="primary" onClick={handleAddPunchClick} disabled={punchLotOid == null} >Add Punch</Button>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closePunchDialog()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth='lg' open={addQCDialogOpen} onClose={() => setAddQCDialogOpen(false)}>
                <DialogTitle><h1>{qcTitle}</h1></DialogTitle>
                <DialogContent >
                    <LotFilter onLotChange={confirmLotQCAddOption}></LotFilter>
                    {isInspection === 1 && (
                        <Select style={{ marginLeft: '25px' }}
                            onChange={handleSelectedSecondaryPhaseForQCChange}
                            value={selectedSecondaryPhaseForQC}>
                            <MenuItem key={'confirm_11'} value={'slab_inspection'}>
                                Slab Inspection
                            </MenuItem>
                            <MenuItem key={'confirm_12'} value={'underground_inspection'}>
                                Underground Inspection
                            </MenuItem>
                            <MenuItem key={'confirm_13'} value={'rough_inspection'}>
                                Rough Inspection
                            </MenuItem>
                            <MenuItem key={'confirm_14'} value={'setout_inspection'}>
                                Setout Inspection
                            </MenuItem>
                        </Select>
                    )}

                    <Select style={{ marginLeft: '25px' }}
                        onChange={handleSelectedPhaseForQCChange}
                        value={selectedPhaseForQC}>
                        <MenuItem key={-1} value={0}>
                            SELECT
                        </MenuItem>
                        {qcPhaseOptions &&
                            qcPhaseOptions.slice().sort((a, b) => a.phase_type.localeCompare(b.phase_type)) // Sort Builders inline
                                .map((phase) => (
                                    <MenuItem key={phase.oid} value={phase.oid}>
                                        {phase.phase_type + '  '}  {phase.date_scheduled !== null && (new Date(phase.date_scheduled).toISOString().split('T')[0])}
                                    </MenuItem>
                                ))}
                    </Select>
                    <br />
                    <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={AddQCValid === false || AddQCValid === null || selectedPhaseForQC === null || selectedPhaseForQC === 0} onClick={() => insertNewQC()}  >Insert</Button>
                    {/* {AddQCValid === false && (<><br /><label style={{ color: 'red' }}>Existing Quality Check For This Lot</label></>)} */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setAddQCDialogOpen(false); setQcPhaseOptions([], selectedSecondaryPhaseForQC); }} >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
    </>
  );
};
export default BasePhase;