import { React, useState, useEffect } from 'react';// adjust this import path to where your JSONTableEditor file is located
import { Dialog, Select, MenuItem, DialogActions,  DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import DynamicForm from '../../DynamicForm';
import JSONTableEditor from '../../JSONTableEditor';
import CentralCalls from '../../../centralCalls';
import { useTheme } from '@mui/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import DeleteConfirmationPopup from '../../DeleteConfirmationPopup';

const useStyles = makeStyles(theme => ({
    customHeader: {
        borderRadius: '10px',
        fontSize: 'large',
        padding: '0px',
        margin: '0px',
        border: 'black solid'
    }
}))

const CompletePhase = ({ phaseType, handleWipLinkClick, endDate, startDate,refreshMenu }) => {
  const [refresh,setRefresh] = useState(false);
    const procedureDetailsRecentComplete = {
        procedureName: 'ui_sp_getPhasePageData',
        parameters: { phase_type: phaseType, page_type: 'complete', start_date : CentralCalls.sqlFormatdate(startDate), end_date: CentralCalls.sqlFormatdate(endDate) },
    }
    const handleRevertRecentClick = async (phaseRow) => {
        await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'open', 'finished': 0 }, 'phase_status', 'update');
        refreshMenu();
        setRefresh(!refresh);
      }
    const handleRevertRecent = async (phaseRow) => {
        //Called after save of revert
        setRefresh(!refresh);
        refreshMenu();
      }
      const customFunctionsRecentComplete = [
        {
          display_name: 'Revert',
          type: 'revert', // type must correspond to a FontAwesome icon name
          func: handleRevertRecentClick
        },
        {
          display_name: 'WIP',
          type: 'overview',
          func: handleWipLinkClick
        }
      ]
    return (
        <>
            <JSONTableEditor
                key={refresh}
                procedureDetails={procedureDetailsRecentComplete}
                onSave={handleRevertRecent}
                sortOrder={{
                    'date_scheduled': 'desc'
                }}
                hideEdit={true}
                customFunctions={customFunctionsRecentComplete} />
        </>
    );
}
export default CompletePhase;