
import React, { useState, useMemo, useEffect } from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import { faPlus, faRefresh, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import JSONTableEditor from '../JSONTableEditor';
// Define the component and destructure the prop from the component's props
const WipUndergroundTicketsSection = ({ selectedLotOid }) => {
    const onUndergroundUpdate = (obj) => { }
    const UndergroundProcedureDetails = useMemo(() => ({
        procedureName: ' ui_sp_wipUndergroundTickets',
        parameters: { 'lot_oid': selectedLotOid },  // If there are any parameters, define them here as an object
      }), [selectedLotOid]);
    return (
        <div>
            <h2>Underground Tickets</h2>
            <JSONTableEditor
                procedureDetails={UndergroundProcedureDetails}
                onsave={onUndergroundUpdate}
                sortOrder={{
                    'oid': 'desc',
                }} />
        </div>
    );
}

export default WipUndergroundTicketsSection;