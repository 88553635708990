import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CentralCalls from '../centralCalls';

const ButtonMenu = ({ onMenuSelect, menuItems, defaultMenuItem }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(defaultMenuItem);
  const [AddPODialogOpen, setAddPODialogOpen] = useState(false);
  const handleButtonClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    onMenuSelect(menuItem);
  };
  const isActive = (filter) => filter.name_id === activeMenuItem?.name_id;

  return (
    <ButtonGroup variant="contained" sx={{ boxShadow: 'none', border: 'none',}}>
      {menuItems.sort((a, b) => a.order - b.order).map((menuItem) => (
        <Button 
          key={menuItem.name_id}
          onClick={() => handleButtonClick(menuItem)}
          sx={{
            borderRadius: '15px',
            fontSize: '10.5px',
            fontWeight: isActive(menuItem) ? 'bold' : 'normal', // Bold for active button
            backgroundColor: isActive(menuItem) ? menuItem.header_back_color : menuItem.header_back_color,
            color: isActive(menuItem) ? 'black' : 'whitesmoke',
            '&:hover': {
              backgroundColor: isActive(menuItem) ? menuItem.header_back_color : 'secondary.dark',
              color: isActive(menuItem) ? 'black' : 'whitesmoke',
            },
          }}
        >
          {menuItem.display_name}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonMenu;