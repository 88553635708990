import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CentralCalls from '../../centralCalls';

const FilterButtonGroup = ({ onPhaseSelect }) => {
  const [activePhase, setActivePhase] = useState('');
  const [phaseTypes, setPhaseTypes] = useState([]);
  const inspection = { 'phase_order': 12, 'is_inspection' : 0, name_id : 'slab_inspection', menu_name :'Inspections', header_back_color : '#0B426B', main_back_color : '#F0F0F5', header_text_color : 'white'}
  const handleButtonClick = (phase) => {
    setActivePhase(phase);
    onPhaseSelect(phase);
  };
  useEffect(() => {
    CentralCalls.fetchTableData('phase_type', {'is_inspection': 0}).then(response => {
      // Check if the inspection object is already in the array
      const inspectionExists = response.some(phase => phase.name_id === inspection.name_id);
      // If inspection is not in the array, add it
      if (!inspectionExists) {
        setPhaseTypes([...response, inspection]);
      } else {
        setPhaseTypes(response);
      }
    });
  }, []);
  const isActive = (filter) => filter.name_id === activePhase.name_id;
  
  return (
    <ButtonGroup variant="contained" sx={{ boxShadow: 'none', border: 'none',}}>
      {phaseTypes.sort((a, b) => a.phase_order - b.phase_order).map((phase) => (
        <Button 
          key={phase.name_id}
          onClick={() => handleButtonClick(phase)}
          sx={{
            borderRadius: '15px',
            fontSize: '10.5px',
            fontWeight: isActive(phase) ? 'bold' : 'normal', // Bold for active button
            backgroundColor: isActive(phase) ? phase.header_back_color : phase.header_back_color,
            color: isActive(phase) ? 'black' : 'whitesmoke',
            '&:hover': {
              backgroundColor: isActive(phase) ? phase.header_back_color : 'secondary.dark',
              color: isActive(phase) ? 'black' : 'whitesmoke',
            },
          }}
          
        >
          {phase.menu_name}
        </Button>
      ))}
              {/* <Button 
          key={'inspections'}
          onClick={() => handleButtonClick(inspection)}
          sx={{
            borderRadius: '15px',
            fontSize:'10.5px',
            backgroundColor: isActive(inspection) ? 'primary.main' : 'secondary.main',
            color: isActive() ? 'black' : 'whitesmoke',
            '&:hover': {
              backgroundColor: isActive(inspection) ? 'primary.dark' : 'secondary.dark',
            },
          }}
        >
          Inspections
        </Button> */}
    </ButtonGroup>
  );
};

export default FilterButtonGroup;