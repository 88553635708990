import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LotFilter from '../workInProgress/LotFilter';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CentralCalls from '../../centralCalls';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    select: {
        minWidth: 200,
    },
    accordion: {
        borderRadius: '15px',
        margin: '10px 0',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}));

const PoItems = ({ poOid }) => {
    const [refreshTable, setRefreshTable] = useState(false);

    const handleSavePoItem = (poList) => {
        // Handle save logic
    };

    const handleAddNewPoItem = (poItem) => {
        // Handle add new item logic
        setRefreshTable((prev) => !prev);  // Toggle the refresh state
    };

    const customFunctions = [
        // Custom functions if any
    ];

    const procedureDetails = {
        procedureName: 'ui_sp_getPoItemData',
        parameters: { 'po_oid': poOid },  
    };

    const procedureDetailsNewItem = {
        procedureName: 'ui_sp_getNewPoItemData',
        parameters: { 'po_oid': poOid },
    };

    return (
        <div>
            <JSONTableEditor
                procedureDetails={procedureDetails}
                onSave={handleSavePoItem}
                allowDelete={false}
                limitHeight={false}
                isSubRowComponent={true}
                sortOrder={{
                    'builder': 'desc',
                    'neighborhood': 'desc',
                    'lot': 'desc'
                }} 
                customFunctions={customFunctions} 
                key={refreshTable} 
            />    
            <DynamicForm 
                onSave={handleAddNewPoItem} 
                procedureDetails={procedureDetailsNewItem} 
                saveName={'Add Item'} 
                crudType={'insert'} 
            />
        </div>
    );
};

export default PoItems;
