import React, { useState, useEffect } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import { faPlus, faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import './WipTabs.css';
import { Auth } from 'aws-amplify';

const WipNotes = ({ selectedLotOid }) => {
    const lotNotesT = [
        { oid: 1, note: 'First note', user: 'Alice', note_date: '2024-07-01' },
        { oid: 2, note: 'Second note', user: 'Bob', note_date: '2024-07-02' },
        { oid: 3, note: 'Third note', user: 'Charlie', note_date: '2024-07-03' },
    ];

    const [userName, setUserName] = useState('');
    const [lotNotes, setLotNotes] = useState([]);
    const [newNote, setNewNote] = useState('');
    const [editNoteOid, setEditNoteOid] = useState(null);
    const [editNoteText, setEditNoteText] = useState('');

    useEffect(() => {
        const getNoteList = async () => {
            if (selectedLotOid) {
                console.log('loading...');
                console.log(selectedLotOid);
                var notes = await CentralCalls.fetchTableData('lot_note', { 'lot_oid': selectedLotOid });
                setLotNotes(notes);
            }
        }
        getNoteList();
    }, [selectedLotOid])
    const handleAddNote = () => {
        const newNoteObj = {
            oid: lotNotes.length + 1, // Assuming oid is auto-incremented
            note: newNote,
            user: userName, // Replace with actual user data
            note_date: new Date().toISOString(),
            lot_oid:selectedLotOid
        };
        CentralCalls.upsertData(newNoteObj, 'lot_note', 'insert');
        setLotNotes([...lotNotes, newNoteObj]);
        setNewNote('');
    };

    const handleEditNote = (oid) => {
        const noteToEdit = lotNotes.find(note => note.oid === oid);
        if (noteToEdit) {
            setEditNoteOid(oid);
            setEditNoteText(noteToEdit.note);
        }
        
        
    };

    const handleSaveNote = (oid, text) => {
        console.log('running update');
        console.log(editNoteText);
        CentralCalls.upsertData({oid:oid, note:editNoteText}, 'lot_note', 'update');
        const updatedNotes = lotNotes.map(note =>
            note.oid === oid ? { ...note, note: editNoteText } : note
        );
        
        console.log(oid);
        
        setLotNotes(updatedNotes);
        setEditNoteOid(null);
        setEditNoteText('');
    };

    useEffect(() => {
        async function setUserInfo() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setUserName(user.attributes.email);
            } catch (error) {
                console.error('Error fetching user info', error);
            }
        }
        setUserInfo();
    }, []);

    return (
        <>
            <h2>Notes</h2>
            {selectedLotOid ? (
                <div className="notes-container">
                    <div className="add-note-container">
                        <input
                            type="text"
                            value={newNote}
                            onChange={(e) => setNewNote(e.target.value)}
                            placeholder="Enter your note here"
                            className="note-input"
                            maxLength="2000"
                        />
                        <button
                            onClick={handleAddNote}
                            disabled={!newNote.trim()}
                            className="add-note-button"
                        >
                            +
                        </button>
                    </div>
                    {lotNotes?.map((lotNote) => (
                        <div className="card" key={lotNote.oid}>
                            <FontAwesomeIcon
                                icon={faEdit}
                                onClick={() => handleEditNote(lotNote.oid)}
                                className="edit-note-icon"
                            />
                            {editNoteOid === lotNote.oid ? (
                                <div>
                                    <input
                                        type="text"
                                        value={editNoteText}
                                        onChange={(e) => setEditNoteText(e.target.value)}
                                        className="note-input"
                                        maxLength="2000"
                                    />
                                    <button onClick={() => handleSaveNote(lotNote.oid)} className="save-note-button">
                                        Save
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <p className="note-content">{lotNote.note}</p>
                                </div>
                            )}
                            <p className="note-user">Posted by: {lotNote.user}</p>
                            <p className="note-date">Date: {new Date(lotNote.note_date).toLocaleDateString()}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No notes available. Please select a lot.</p>
            )}
        </>
    );
}

export default WipNotes;
