import React from 'react';
import { Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faP,faTasks } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function PhaseLink({ phase_type }) {
    const navigate = useNavigate();

    const handlePhaseLinkClick = async () => {
        const path = '/phase/' + phase_type;
        navigate(path);
    }
    return (
        <IconButton
            
            size="small"
            color="primary"
            onClick={() => handlePhaseLinkClick()} >
            <FontAwesomeIcon icon={faTasks} title={"Phase"} />
        </IconButton>
    );
}

export default PhaseLink;
