import React, { useState, useEffect, useMemo, useRef } from 'react';
import LotFilter from './LotFilter';
import MaterialSelector from './MaterialSelector';
import DisplayTable from '../displayTable/DisplayTable';
import DynamicForm from '../DynamicForm';
import JSONTableEditor from '../JSONTableEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CentralCalls from '../../centralCalls';
import { ContactsOutlined, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip, Select, MenuItem, FormControl, TextField, Divider } from '@mui/material';
import { faPlus, faRefresh, faPrint, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';

const LotView = () => {
  const [SelectedLot, setSelectedLot] = useState(null);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [addPhaseDialogOpen, setAddPhaseDialogOpen] = useState(false);
  const [addMaterialRoomDialogOpen, setAddMaterialRoomDialogOpen] = useState(false);
  const [deleteLotDialogOpen,setDeleteLotDialogOpen] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [addPODialogOpen, setAddPODialogOpen] = useState(false);
  const [selectedPhaseRow, setSelectedPhaseRow] = useState(null);
  const [refreshIconKey, setRefreshIconKey] = useState(1000);
  const [refreshPoUpdateKey, setRefreshPoUpdateKey] = useState(3000);
  const [refreshPhaseKey, setRefreshPhaseKey] = useState(4000);
  const [newPoPhases, setnewPoPhases] = useState(null);
  const [selectedPoPhaseOid, setSelectedPoPhaseOid] = useState(null);
  const [total, setTotal] = useState(null);
  const [materialTotal, setMaterialTotal] = useState(null);
  const [PermitStatus, setPermitStatus] = useState(null);
  const [newMaterialModelOid, setNewMaterialModelOid] = useState(null);
  const [refreshMaterialSelectKey, setRefreshMaterialSelectKey] = useState(5000);
  const [triggerMaterialTotalRefresh, setTriggerMaterialTotalRefresh] = useState(false);
  const [roomList, setRoomList] = useState([]);
  const [roomsPerLot, setRoomsPerLot] = useState([]);
  const roomName = useRef('');
  const [addRoomToLotOid, setAddRoomToLotOid] = useState(0);
  const [selectedMaterialAddRoom, setSelectedMaterialAddRoom] = useState({ oid: 0, name: 'Select' });
  //const [selectedRoomLot, setSelectedRoomLot] = useState({});
  const handlePoPhaseChange = async (event) => {
    setSelectedPoPhaseOid(event.target.value);
  };
  const handleLotChange = async (lot_oid) => {
    setSelectedLot(Number(lot_oid));
    const rows = await CentralCalls.fetchTableData('vw_phase_stat', { 'lot_oid': lot_oid });
    setnewPoPhases(rows);
  };
  const handleMaterialModelSelected = async (modelRow) => {
    console.log('model row...')
    console.log(modelRow);
    await setSelectedMaterialAddRoom(modelRow.room_oid);
    setNewMaterialModelOid(modelRow.model_oid);
  }
  const handleSavePhaseDetails = async (slabRow) => {
    //'is_punch': phaseRow.is_punch ? 1 : 0
    CentralCalls.upsertData({ 'oid': slabRow.phase_oid, 'date_scheduled': slabRow.date_scheduled, 'builder_date': slabRow.builder_date, 'is_punch': slabRow.is_punch ? 1 : 0 }, 'phase', 'update');
  };
  const addNewPoToPhase = async () => {
    //await CentralCalls.upsertData({ 'phase_oid': selectedPhase}, 'po', 'insert');
   
    setRefreshIconKey(prevKey => prevKey + 1);
    setStatusDialogOpen(false);
    setAddPODialogOpen(false);
  }
  const addPoClick = async (phaseRow) => {
    setSelectedPhase(phaseRow.phase_oid);
    setStatusDialogOpen(true);
    setSelectedPhaseRow(phaseRow);
    //setRefreshPoUpdateKey(prevKey => prevKey + 1);
  }
  const onLotUpdate = (obj) => { }
  const onPoUpdate = (poRow) => {
    CentralCalls.upsertData({ 'oid': poRow.phase_oid, 'is_punch': poRow.is_punch ? 1 : 0 }, 'phase', 'update');
  }
  const onPermitUpdate = (obj) => { }
  const onInspectionUpdate = (obj) => { }
  const onUndergroundUpdate = (obj) => { }
  const procedureAddPOByPhaseDetailsUpdate = useMemo(() => ({
    procedureName: 'ui_sp_wipAddPoByPhase',
    parameters: { 'phase_oid': selectedPhase, 'page_type': 'update' },  // If there are any parameters, define them here as an object
  }), [selectedPhase]);
  const procedureAddPOByPhaseDetailsInsert = useMemo(() => ({
    procedureName: 'ui_sp_wipAddPoByPhase',
    parameters: { 'phase_oid': selectedPhase, 'page_type': 'insert' },  // If there are any parameters, define them here as an object
  }), [selectedPhase]);
  const procedureAddPOByLotDetailsInsert = useMemo(() => ({
    procedureName: 'ui_sp_wipAddPoByPhase',
    parameters: { 'lot_oid': SelectedLot, 'page_type': 'show_phase_insert' },  // If there are any parameters, define them here as an object
  }), [SelectedLot]);
  const procedureAddPhaseDetails = useMemo(() => ({
    procedureName: 'ui_sp_wipInsertPunchPhase',
    parameters: { lot_oid: SelectedLot },  // If there are any parameters, define them here as an object
  }), [SelectedLot]);
  const procedureDetails = useMemo(() => ({
    procedureName: 'ui_sp_getLotPageInsert',
    parameters: { 'lot_oid': SelectedLot, 'page_type': 'wip' },  // If there are any parameters, define them here as an object
  }), [SelectedLot]);
  const slabProcedureDetails = useMemo(() => ({
    procedureName: 'ui_sp_getLotOverview',
    parameters: { 'lot_oid': SelectedLot },  // If there are any parameters, define them here as an object
  }), [SelectedLot]);
  const MaterialTableProcedureDetails = useMemo(() => ({
    procedureName: 'ui_sp_materialsByLotPage',
    parameters: { 'lot_oid': SelectedLot },
  }), [SelectedLot])
  const customFunctionsWipPhases = [
    {
      display_name: 'Add PO',
      type: 'money', // type must correspond to a FontAwesome icon name
      func: addPoClick,
      alwaysEnable: true
    }
  ]
  const customFunctionsEditMaterials = [

  ]
  // const getPoTotal = async () => {
  //   const lotIdToSend = SelectedLot && !isNaN(SelectedLot) ? SelectedLot : 0;
  //   var tot = await CentralCalls.callStoredProcedureWithParams('ui_sp_wipPoTotal', { 'lot_oid': SelectedLot });
  //   return tot;
  // }
  const runPermitStatus = async () => {
    const lotIdToSend = SelectedLot && !isNaN(SelectedLot) ? SelectedLot : 0;
    var stat = await CentralCalls.callStoredProcedureWithParams('check_permitRequired', { 'lot_oid': lotIdToSend });
    setPermitStatus(stat.status);
  }
  const handleSaveMaterialEditPostRun = async () => {
    setTriggerMaterialTotalRefresh(prev => !prev);
    getRoomList();
  }
  const addNewPhasePostSave = async (phaseRow) => {
    //await CentralCalls.upsertData( {phase_oid : phaseRow.oid, status_date:  phaseRow.date_complete, comments: phaseRow.comments }, 'phase_status', 'insert');
    setAddPhaseDialogOpen(false);

    setRefreshPhaseKey(refreshPhaseKey + 1);
  }
  const handleSaveAddPOPhaseSave = async (poRow) => {

  }
  const handleDeleteLot = async () => {
    console.log('deleting LOT');
    await CentralCalls.callStoredProcedureWithParams('sp_deleteLot', {lot_oid : SelectedLot});
    const path = '/';
    window.open(path, "_blank");
  }
  const addNewMaterialModel = async () => {
    await CentralCalls.upsertData({ 'lot_oid': SelectedLot, 'material_room_oid': selectedMaterialAddRoom, 'material_model_oid': newMaterialModelOid }, 'room_material', 'insert');
    setRefreshMaterialSelectKey(refreshMaterialSelectKey + 1);
  }
  const addNewRoomToLot = async () => {
    if (addRoomToLotOid && addRoomToLotOid > 0) {
      await CentralCalls.upsertData({ 'lot_oid': SelectedLot, 'material_room_oid': addRoomToLotOid }, 'lot_room', 'insert');
      setAddRoomToLotOid(0);
      setAddMaterialRoomDialogOpen(false);
      setRefreshMaterialSelectKey(refreshMaterialSelectKey + 1);
    }
  }
  const handleAddNewRoom = async () => {
    if (roomName !== '') {
      var newRoom = await CentralCalls.upsertData({ 'name': roomName.current.value }, 'material_room', 'insert');
      setRoomList((prevRoomList) => [...prevRoomList, { name: roomName.current.value, oid: newRoom.oid }]);
      roomName.current.value = '';
    }
  }
  const POProcedureDetails = useMemo(() => ({
    procedureName: 'ui_sp_wipPoEdit',
    parameters: { 'lot_oid': SelectedLot },  // If there are any parameters, define them here as an object
  }), [SelectedLot]);
  const PermitProcedureDetails = useMemo(() => ({
    procedureName: ' ui_sp_wipPermitData',
    parameters: { 'lot_oid': SelectedLot },  // If there are any parameters, define them here as an object
  }), [SelectedLot]);
  const UndergroundProcedureDetails = useMemo(() => ({
    procedureName: ' ui_sp_wipUndergroundTickets',
    parameters: { 'lot_oid': SelectedLot },  // If there are any parameters, define them here as an object
  }), [SelectedLot]);
  const InspectionProcedureDetails = useMemo(() => ({
    procedureName: ' ui_sp_wipInspections',
    parameters: { 'lot_oid': SelectedLot },  // If there are any parameters, define them here as an object
  }), [SelectedLot]);
  const getRoomList = async () => {
    var rooms = await CentralCalls.callStoredProcedureWithParams('sp_getAvailableRoomsPerLot', { 'lot_oid': SelectedLot })
    setRoomList(rooms);
    console.log('roomos...')
    console.log(rooms);
  }
  const sendToPrint = async () => {
    //const urlParams = 'qry=' + qry + '&employee_oids=' + selectedPlumbers.map(item => item.oid) + '&start_date=' + CentralCalls.sqlFormatdate(startDate) + '&end_date=' + CentralCalls.sqlFormatdate(endDate);
    const path = '/printMaterials?lot_oid=' + SelectedLot;
    window.open(path, "_blank");
  }
  useEffect(() => {
    const getPoTotal = async () => {
      if (SelectedLot) {
        var tot = await CentralCalls.callStoredProcedureWithParams('ui_sp_wipPoTotal', { 'lot_oid': SelectedLot });
        setTotal(tot[0].total);
        runPermitStatus();
      }
    }
    getPoTotal();
  }, [SelectedLot]);

  useEffect(() => {
    // const getRoomList = async () => {
    //   var rooms = await CentralCalls.callStoredProcedureWithParams('sp_getAvailableRoomsPerLot', {'lot_oid': SelectedLot})
    //   setRoomList(rooms);
    // }
    getRoomList();
  }, [SelectedLot, getRoomList]);

  useEffect(() => {
    const getRoomsForLot = async () => {
      if (SelectedLot) {
        var roomsPerLot = await CentralCalls.callStoredProcedureWithParams('sp_getRoomsPerLot', { 'lot_oid': SelectedLot });
        setRoomsPerLot(roomsPerLot);
      }
    }
    getRoomsForLot();
  }, [SelectedLot]);

  useEffect(() => {
    const getMaterialTotal = async () => {
      if (SelectedLot) {
        var tot = await CentralCalls.callStoredProcedureWithParams('sp_getMaterialCostTotal', { 'lot_oid': SelectedLot });
        setMaterialTotal(tot[0].total);
      }
    }
    getMaterialTotal();
  }, [triggerMaterialTotalRefresh, newMaterialModelOid, SelectedLot]);
 
  return (
    <>
      <h1>Work In Progress
        <IconButton color="primary" aria-label="save" onClick={() => { window.location.href = '/wip?lot_oid=' + SelectedLot; }} disabled={!SelectedLot} size="medium">
          <FontAwesomeIcon icon={faRefresh} />
        </IconButton>
      </h1>
      <h2>Select Lot</h2>
      <LotFilter onLotChange={handleLotChange} />
      <h2>Lot <IconButton color="primary" aria-label="save" onClick={() => setDeleteLotDialogOpen(true)} size="medium">
              <FontAwesomeIcon icon={faTrashCan} />
            </IconButton></h2>
      <DynamicForm procedureDetails={procedureDetails} onSave={onLotUpdate} saveName={'Update'} crudType={'update'} ></DynamicForm>
      <h2>PO's ({CentralCalls.formatTotal(total)})
        {SelectedLot && (
          <Tooltip title="Add PO">
            <IconButton color="primary" aria-label="save" onClick={() => setAddPODialogOpen(true)} size="medium">
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>)}
      </h2>
      <JSONTableEditor key={refreshIconKey} allowDelete={true}
        procedureDetails={POProcedureDetails} onSave={onPoUpdate} sortOrder={{ 'date_billed': 'asc' }} />
      <h2>Permit ({PermitStatus})</h2>
      <JSONTableEditor
        procedureDetails={PermitProcedureDetails}
        onsave={onPermitUpdate}
        sortOrder={{ 'date_applied': 'asc' }} />
      <h2>Underground Tickets</h2>
      <JSONTableEditor
        procedureDetails={UndergroundProcedureDetails}
        onsave={onUndergroundUpdate}
        sortOrder={{
          'oid': 'desc',
        }} />

      <h2>Inspections</h2>
      <JSONTableEditor
        procedureDetails={InspectionProcedureDetails}
        onsave={onInspectionUpdate}
        sortOrder={{
          'is_cancelled': 'asc',
          'status_date' : 'asc',
          'phase_order' : 'asc',
          
        }} />
      <h2>Phases
        <Tooltip title="Add New Task To Phase">
          <IconButton color="primary" aria-label="save" onClick={() => setAddPhaseDialogOpen(true)} size="medium">
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      </h2>
      <JSONTableEditor
        key={refreshPhaseKey}
        customFunctions={customFunctionsWipPhases}
        procedureDetails={slabProcedureDetails}
        onSave={handleSavePhaseDetails}
        sortOrder={{
          'is_cancelled': 'asc',
          'status_date': 'asc',
          'phase_order': 'asc'
        }} />
      <h2>
        <IconButton
          color="primary"
          aria-label="save"
          onClick={() => { sendToPrint() }}
          size="medium">
          <FontAwesomeIcon icon={faPrint} />
        </IconButton>
        Materials
        ({CentralCalls.formatTotal(materialTotal)})
        <Tooltip title="Add Room">
          <IconButton color="primary" aria-label="save" onClick={() => setAddMaterialRoomDialogOpen(true)} size="medium">
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      </h2>
      <div style={{ display: 'flex' }}>
        {/* <Select style={{ width: '300px' }}
        value={selectedRoomPerLot.oid}
        onChange={(event) => { console.log(roomsPerLot); console.log(event.target.value); setSelectedRoomPerLot(roomsPerLot.find(x => x.oid === event.target.value)); console.log(selectedRoomPerLot);}} >
        <MenuItem key={0} value={0}>
          Select Room
        </MenuItem>
        {roomsPerLot &&
          roomsPerLot.slice().sort((a, b) => a.name.localeCompare(b.name))
            .map((option) => (
              <MenuItem key={option.oid} value={option.oid}>
                {option.name}
              </MenuItem>
            ))}
      </Select> */}

      </div>

      {/* {roomsPerLot && roomsPerLot.map((room) => (
        <>
        */}
      {/* <h4>{selectedRoomPerLot.name}</h4> */}
      <JSONTableEditor
        procedureDetails={MaterialTableProcedureDetails}
        onSave={handleSaveMaterialEditPostRun}
        key={selectedMaterialAddRoom}
        allowDelete={true}
        customFunctions={customFunctionsEditMaterials}
        sortOrder={{
          'room': 'asc',
          'material_type': 'asc',
          'material_desc': 'asc',
          'material_model': 'asc'
        }}   >
      </JSONTableEditor>
      <h4>Add Material</h4>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '1' }}>
          <MaterialSelector key={refreshMaterialSelectKey} onMaterialModelChange={handleMaterialModelSelected} lot_oid={SelectedLot} />
        </div>
      </div>
      <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={newMaterialModelOid === null} aria-label="save" onClick={() => addNewMaterialModel()} size="medium">
        Insert
      </Button>
      {/* </> 
       ))}*/}
      <Dialog fullWidth={true} maxWidth='sm' open={addMaterialRoomDialogOpen} onClose={() => setAddMaterialRoomDialogOpen(false)}>
        <DialogContent >
          <>
            <h2>Add Room</h2>
            <Select style={{ width: '300px' }}
              value={addRoomToLotOid}
              onChange={(event) => { setAddRoomToLotOid(event.target.value) }} >
              <MenuItem key={0} value={0}>
                Select Room
              </MenuItem>
              {roomList &&
                roomList.slice().sort((a, b) => a.name.localeCompare(b.name))
                  .map((option) => (
                    <MenuItem key={option.oid} value={option.oid}>
                      {option.name}
                    </MenuItem>
                  ))}
            </Select>

            <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={addRoomToLotOid === 0} aria-label="save" onClick={() => addNewRoomToLot()} size="medium">
              Insert
            </Button>
            {/* <h2>Insert New Room Option</h2>
            <div style={{ display: 'flex' }}>
              <TextField style={{ width: '300px' }}
                inputRef={roomName}
                inputProps={{ maxLength: 50 }}
                placeholder="Enter room name"
              ></TextField>
              <IconButton onClick={handleAddNewRoom} >
                <AddIcon />
              </IconButton>
            </div>  */}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddMaterialRoomDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth={true} maxWidth='lg' open={statusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
        <DialogContent >
          <>
            <h2>New Po</h2>
            <DynamicForm procedureDetails={procedureAddPOByPhaseDetailsInsert}
              onSave={addNewPoToPhase}
              saveName='Add'
              crudType='insert' ></DynamicForm>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth={true} maxWidth='lg' open={addPODialogOpen} onClose={() => setAddPODialogOpen(false)}>
        <DialogTitle></DialogTitle>
        <DialogContent >
          <h2>New PO</h2>
          <DynamicForm procedureDetails={procedureAddPOByLotDetailsInsert}
            onSave={addNewPoToPhase}
            saveName='Add'
            crudType='insert' ></DynamicForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddPODialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth={true} maxWidth='lg' open={addPhaseDialogOpen} onClose={() => setAddPhaseDialogOpen(false)}>
        <DialogTitle></DialogTitle>
        <DialogContent >
          <h2>New Phase</h2>
          <DynamicForm procedureDetails={procedureAddPhaseDetails}
            onSave={addNewPhasePostSave}
            saveName='Add'
            crudType='insert' ></DynamicForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddPhaseDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth={true} maxWidth='lg' open={deleteLotDialogOpen} onClose={() => setDeleteLotDialogOpen(false)}>
        <DialogTitle></DialogTitle>
        <DialogContent >
          <h2>Confirm Delete Lot</h2>     
          <Button variant="contained" color="secondary" onClick={() => handleDeleteLot()}>Delete</Button>     
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteLotDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default LotView;
