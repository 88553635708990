import React from 'react';
import { Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
function WipLink({ lot_oid }) {
    const navigate = useNavigate();

    const handleWipLinkClick = async () => {
        const path = '/wip?lot_oid=' + lot_oid;
        navigate(path);
    }
    return (
        <IconButton
            
            size="small"
            color="primary"
            onClick={() => handleWipLinkClick()} >
            <FontAwesomeIcon icon={faEye} title={"Wip"} />
        </IconButton>
    );
}

export default WipLink;
